 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .no--page {
        margin: 0px 10px;
    }
    
    .no--page-col1 {
        padding: 0px;
    }
    
    .no--page-col2 {
        padding: 32px;
    }
    
    .no--page-t1 {
        color: #141414;
        font-size: 18px;
    }
    
    .no--page-t2 {
        font-size: 64px;
        line-height: 80px;
        width: 450px;
    }
    
    .no--page-btn {
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        width: 200px;
        margin-right: 32px;
        padding: 14px;
    }
    
    .no--page-img {
        width: 400px;
        height: 400px;
        margin-left: -5%;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .no--page {
        margin: 50px 50px;
    }
    
    .no--page-col1 {
        padding: 0px;
    }
    
    .no--page-col2 {
        padding: 32px;
    }
    
    .no--page-t1 {
        color: #141414;
        font-size: 18px;
    }
    
    .no--page-t2 {
        font-size: 64px;
        line-height: 80px;
        width: 450px;
    }
    
    .no--page-btn {
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        width: 200px;
        margin-right: 32px;
        padding: 14px;
    }
    
    .no--page-img {
        width: 400px;
        height: 400px;
        margin-left: -5%;
    }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

    .no--page {
        margin: 50px 10px;
    }
    
    .no--page-col1 {
        padding: 32px;
    }
    
    .no--page-col2 {
        padding: 32px;
    }
    
    .no--page-t1 {
        color: #141414;
        font-size: 18px;
    }
    
    .no--page-t2 {
        font-size: 64px;
        line-height: 80px;
        width: 490px;
    }
    
    .no--page-btn {
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        width: 200px;
        margin-right: 32px;
        padding: 14px;
    }
    
    .no--page-img {
        width: 500px;
        height: 500px;
        margin-left: 10%;
    }
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

    .no--page {
        margin: 50px 40px;
    }
    
    .no--page-col1 {
        padding: 32px;
    }
    
    .no--page-col2 {
        padding: 0px;
    }
    
    .no--page-t1 {
        color: #141414;
        font-size: 18px;
    }
    
    .no--page-t2 {
        font-size: 64px;
        line-height: 80px;
        width: 510px;
    }
    
    .no--page-btn {
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        width: 200px;
        margin-right: 32px;
        padding: 14px;
    }
    
    .no--page-img {
        width: 450px;
        height: 450px;
        margin-left: 5%;
    }
 
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .no--page {
        margin: 50px 100px;
    }
    
    .no--page-col1 {
        padding: 32px;
    }
    
    .no--page-col2 {
        padding: 32px;
    }
    
    .no--page-t1 {
        color: #141414;
        font-size: 18px;
    }
    
    .no--page-t2 {
        font-size: 64px;
        line-height: 80px;
        width: 510px;
    }
    
    .no--page-btn {
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        width: 200px;
        margin-right: 32px;
        padding: 14px;
    }
    
    .no--page-img {
        width: 450px;
        height: 450px;
        margin-left: 5%;
    }
 } 