 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .legals {
        margin: 0px 20px;
    }
    
    .legals--col1 {
        padding: 32px;
    }
    
    .legals--col2 {
        padding: 32px;
        margin-top: 50px;
    }
    
    .legals--t1 {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        margin-bottom: 62px;
    }
    
    .legals--sidebar-text {
        padding: 16px;
        color: #141414;
        font-size: 20px;
        margin-bottom: 12px;
    }
    
    .legals--sidebar-text-active {
        padding: 16px;
        background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
        border-radius: 8px;
    }
    
    .legals--sidebar-text:hover {
        background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
        border-radius: 8px;
    }
    
    .legals--t2 {
        font-size: 48px;
        font-weight: 620;
        margin-top: -12px;
    }
    
    .legals--t3 {
        margin-bottom: 32px;
        font-size: 18px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .legals {
        margin: 50px 100px;
    }
    
    .legals-col1 {
        padding: 32px;
    }
    
    .legals-col2 {
        padding: 32px;
    }
    
    .legals--t1 {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        margin-bottom: 62px;
    }
    
    .legals--sidebar-text {
        padding: 16px;
        color: #141414;
        font-size: 20px;
        margin-bottom: 12px;
    }
    
    .legals--sidebar-text-active {
        padding: 16px;
        background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
        border-radius: 8px;
    }
    
    .legals--sidebar-text:hover {
        background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
        border-radius: 8px;
    }
    
    .legals--t2 {
        font-size: 48px;
        font-weight: 620;
        margin-top: -12px;
    }
    
    .legals--t3 {
        margin-bottom: 32px;
        font-size: 18px;
    }
 } 


