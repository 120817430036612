.link {
    text-decoration: none;
}

#newButton {
    display: none;
}

#closeApprove-x {
    display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#viewOfferDetails {
  display: block;
}

#buyOfferList {
  display: block;
}

#openError {
  display: none;
}

#sell-orders--no-item {
  display: none;
}


#all-errors {
  display: block;
}

.allErrors--icon {
  font-size: 40px;
  background: rgba(255, 0, 0, 0.05);
  border-radius: 100%;
  text-align: center;
  padding: 28px 42px;
  color: red;
  margin-left: 38.5%;
}

#initialized-x {
  display: none;
}





.loader {
  width: 18px;
  height: 18px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  width: 38px;
  height: 38px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}



.loader2 {
  width: 18px;
  height: 18px;
  border: 5px solid #2C3C5D;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse2 1s linear infinite;
}
.loader2:after {
  content: '';
  position: absolute;
  width: 38px;
  height: 38px;
  border: 5px solid #2C3C5D;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp2 1s linear infinite;
}

@keyframes scaleUp2 {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse2 {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}

.loader3 {
  width: 18px;
  height: 18px;
  border: 5px solid #2C3C5D;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse3 1s linear infinite;
}
.loader3:after {
  content: '';
  position: absolute;
  width: 38px;
  height: 38px;
  border: 5px solid #2C3C5D;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp3 1s linear infinite;
}

@keyframes scaleUp3 {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse3 {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}

.header--menu {
  width: 120px;
  /* height: 30px; */
  margin: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
  border-radius: 15px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  top: -120px;
}

.header--menu-icon {
  font-size: 16px;
  margin-right: 12px;
}

.header--menu-t:hover {
  opacity: 0.6;
}