 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .buy--offer {
        margin: 50px 50px; 
        overflow: scroll;
   }
   
   .buy--offer-header {
       background-color: #2C3C5D;
       padding: 32px;
   }
   
   .buy--offer-header-head {
        position: relative;
       text-align: center;
       color: #fff;
       margin-right: 18px;
       font-size: 16px;
       bottom: 12px;
   }
   
   .buy--offer-header-list {
       width: 100%;
       background: #FFFFFF;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       margin-right: 102px;
       margin-bottom: 24px;
   }
   
   .buy--offer-header-btn {
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       width: 100%;
   }
   
   .buy--offer-t1 {
       text-align: center;
       font-size: 24px;
       color: #141414;
   }
   
   .buy--offer-h-r {
       position: relative;
       top: -65px;
       right: -34px;
       background-color: #fff;
       border: 2px solid #141414;
       border-radius: 5px;
       font-size: 14px;
       color: #141414;
       padding: 12px;
   }
   
   .buy--offer-list {
       margin: 150px 0px;
   }
   
   .buy--offer-div {
       background: #FFFFFF;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
       border-radius: 15px;
       margin-top: 130px;
       /* border-bottom: 1px solid black; */
       width: 200%;
       padding-bottom: 70px;
   }
   
   .buy--offer-tt1 {
    display: inline-block;
    position: relative;
    top: -12px;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
}
   
   .buy--offer-tt2 {
       position: relative;
       top: -24px;
       left: 58px;
       font-size: 14px;
   }
   
   .buy--offer-ttt1 {
       font-size: 16px;
       position: absolute;
       margin-right: 0px;
       text-align: right;
       right: 24px;
       top: -6px;
       /* right: -8px */
   }
   
   .buy--offer-ttt2 {
       font-size: 14px;
   }
   
   .buy--offer-div-up {
       position: relative;
       top: -68px;
       padding: 0px 24px;
   }
   
   .buy--offer-icon {
       font-size: 20px;
       background-color: #0F49C0;
       color: #fff;
       padding: 12px;
       border-radius: 100%;
       margin-right: 12px;
   }
   
   .buy--offer-div-btn1 {
       position: relative;
       background: rgba(15, 73, 192, 0.05);
       color: #0F49C0;
       border-radius: 8px;
       padding: 12px;
       border: none;
       left: -178px;
       bottom: 0px;
   }
   
   .buy--offer-div-btn2 {
       position: relative;
       right: 28px;
       bottom: 24px;
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px;
       border: none;
   }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .buy--offer {
        margin: 50px 50px; 
        overflow: scroll;
   }
   
   .buy--offer-header {
       background-color: #2C3C5D;
       padding: 32px;
   }
   
   .buy--offer-header-head {
        position: relative;
       text-align: center;
       color: #fff;
       margin-right: 18px;
       font-size: 16px;
       bottom: 12px;
   }
   
   .buy--offer-header-list {
        width: 100%;
       background: #FFFFFF;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       margin-right: 102px;
       margin-bottom: 24px;
   }
   
   .buy--offer-header-btn {
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       width: 100%;
   }
   
   .buy--offer-t1 {
       text-align: center;
       font-size: 24px;
       color: #141414;
   }
   
   .buy--offer-h-r {
       position: relative;
       top: -65px;
       right: 0px;
       background-color: #fff;
       border: 2px solid #141414;
       border-radius: 5px;
       font-size: 14px;
       color: #141414;
       padding: 12px;
   }
   
   .buy--offer-list {
       margin: 150px 0px;
   }
   
   .buy--offer-div {
       background: #FFFFFF;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
       border-radius: 15px;
       margin-top: 130px;
       /* border-bottom: 1px solid black; */
       width: 100%;
       padding-bottom: 70px;
   }
   
   .buy--offer-tt1 {
    display: inline-block;
    position: relative;
    top: -12px;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
}
   
   .buy--offer-tt2 {
       position: relative;
       top: -24px;
       left: 58px;
       font-size: 14px;
   }
   
   .buy--offer-ttt1 {
       font-size: 16px;
       position: absolute;
       margin-right: 0px;
       text-align: right;
       right: 24px;
       top: -6px;
       /* right: -8px */
   }
   
   .buy--offer-ttt2 {
       font-size: 14px;
   }
   
   .buy--offer-div-up {
       position: relative;
       top: -68px;
       padding: 0px 24px;
   }
   
   .buy--offer-icon {
       font-size: 20px;
       background-color: #0F49C0;
       color: #fff;
       padding: 12px;
       border-radius: 100%;
       margin-right: 12px;
   }
   
   .buy--offer-div-btn1 {
       position: relative;
       background: rgba(15, 73, 192, 0.05);
       color: #0F49C0;
       border-radius: 8px;
       padding: 12px;
       border: none;
       left: -178px;
       bottom: 0px;
   }
   
   .buy--offer-div-btn2 {
       position: relative;
       right: 28px;
       bottom: 0px;
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px;
       border: none;
   }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

    .buy--offer {
        margin: 50px -50px; 
        overflow: scroll;
   }
   
   .buy--offer-header {
       background-color: #2C3C5D;
       padding: 32px;
   }
   
   .buy--offer-header-head {
       text-align: center;
       color: #fff;
       margin-right: 18px;
       font-size: 12px;
   }
   
   .buy--offer-header-list {
       background: #FFFFFF;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       margin-right: 102px;
   }
   
   .buy--offer-header-btn {
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
   }
   
   .buy--offer-t1 {
       text-align: center;
       font-size: 24px;
       color: #141414;
   }
   
   .buy--offer-h-r {
       position: relative;
       top: -65px;
       right: 130px;
       background-color: #fff;
       border: 2px solid #141414;
       border-radius: 5px;
       font-size: 14px;
       color: #141414;
       padding: 12px;
   }
   
   .buy--offer-list {
       margin: 150px 100px;
   }
   
   .buy--offer-div {
       background: #FFFFFF;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
       border-radius: 15px;
       margin-top: 130px;
       /* border-bottom: 1px solid black; */
       padding-bottom: 70px;
       width: 100%;
   }
   
   .buy--offer-tt1 {
    display: inline-block;
    position: relative;
    top: -12px;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
}
   
   .buy--offer-tt2 {
       position: relative;
       top: -24px;
       left: 58px;
       font-size: 14px;
   }
   
   .buy--offer-ttt1 {
       font-size: 16px;
       position: absolute;
       margin-right: 0px;
       text-align: right;
       right: 24px;
       top: -6px;
       /* right: -12px */
   }
   
   .buy--offer-ttt2 {
       font-size: 14px;
   }
   
   .buy--offer-div-up {
       position: relative;
       top: -68px;
       padding: 0px 24px;
   }
   
   .buy--offer-icon {
       font-size: 20px;
       background-color: #0F49C0;
       color: #fff;
       padding: 12px;
       border-radius: 100%;
       margin-right: 12px;
   }
   
   .buy--offer-div-btn1 {
       position: relative;
       background: rgba(15, 73, 192, 0.05);
       color: #0F49C0;
       border-radius: 8px;
       padding: 12px;
       border: none;
       left: -178px;
       bottom: 0px;
   }
   
   .buy--offer-div-btn2 {
       position: relative;
       right: -265px;
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px;
       border: none;
   }
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .buy--offer {
        margin: 50px 50px; 
   }
   
   .buy--offer-header {
       background-color: #2C3C5D;
       padding: 32px;
   }
   
   .buy--offer-header-head {
       text-align: center;
       color: #fff;
       margin-right: 18px;
       font-size: 12px;
   }
   
   .buy--offer-header-list {
       background: #FFFFFF;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       margin-right: 102px;
       width: 210px;
   }
   
   .buy--offer-header-btn {
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       width: 160px;
   }
   
   .buy--offer-t1 {
       text-align: center;
       font-size: 24px;
       color: #141414;
   }
   
   .buy--offer-h-r {
       position: relative;
       top: -65px;
       right: 130px;
       background-color: #fff;
       border: 2px solid #141414;
       border-radius: 5px;
       font-size: 14px;
       color: #141414;
       padding: 12px;
   }
   
   .buy--offer-list {
       margin: 150px 100px;
   }
   
   .buy--offer-div {
       background: #FFFFFF;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
       border-radius: 15px;
       margin-top: 130px;
       /* border-bottom: 1px solid black; */
       padding-bottom: 70px;
       width: 100%;
   }
   
   .buy--offer-tt1 {
    display: inline-block;
    position: relative;
    top: -12px;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
}
   
   .buy--offer-tt2 {
       position: relative;
       top: -24px;
       left: 58px;
       font-size: 14px;
   }
   
   .buy--offer-ttt1 {
       font-size: 16px;
       position: absolute;
       margin-right: 0px;
       text-align: right;
       right: 24px;
       top: -6px;
       /* right: -8px */
   }
   
   .buy--offer-ttt2 {
       font-size: 14px;
   }
   
   .buy--offer-div-up {
       position: relative;
       top: -68px;
       padding: 0px 24px;
   }
   
   .buy--offer-icon {
       font-size: 20px;
       background-color: #0F49C0;
       color: #fff;
       padding: 12px;
       border-radius: 100%;
       margin-right: 12px;
   }
   
   .buy--offer-div-btn1 {
       position: relative;
       background: rgba(15, 73, 192, 0.05);
       color: #0F49C0;
       border-radius: 8px;
       padding: 12px;
       border: none;
       left: -178px;
       bottom: 0px;
   }
   
   .buy--offer-div-btn2 {
       position: relative;
       right: -265px;
       bottom: 0px;
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px;
       border: none;
   }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .buy--offer {
        margin: 50px 50px; 
   }
   
   .buy--offer-header {
       background-color: #2C3C5D;
       padding: 32px;
   }
   
   .buy--offer-header-head {
       text-align: center;
       color: #fff;
       margin-right: 18px;
       font-size: 12px;
   }
   
   .buy--offer-header-list {
       background: #FFFFFF;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
       margin-right: 102px;
   }
   
   .buy--offer-header-btn {
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px 16px;
       border: none;
   }
   
   .buy--offer-t1 {
       text-align: center;
       font-size: 24px;
       color: #141414;
   }
   
   .buy--offer-h-r {
       position: relative;
       top: -65px;
       right: 130px;
       background-color: #fff;
       border: 2px solid #141414;
       border-radius: 5px;
       font-size: 14px;
       color: #141414;
       padding: 12px;
   }
   
   .buy--offer-list {
       margin: 150px 100px;
   }
   
   .buy--offer-div {
       background: #FFFFFF;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
       border-radius: 15px;
       margin-top: 130px;
       /* border-bottom: 1px solid black; */
       padding-bottom: 70px;
   }
   
   .buy--offer-tt1 {
    display: inline-block;
    position: relative;
    top: -12px;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
}
   
   .buy--offer-tt2 {
       position: relative;
       top: -24px;
       left: 58px;
       font-size: 14px;
   }
   
   .buy--offer-ttt1 {
       font-size: 16px;
       position: absolute;
       margin-right: 0px;
       text-align: right;
       right: 24px;
       top: -6px;
       /* right: -8px */
   }
   
   .buy--offer-ttt2 {
       font-size: 14px;
   }
   
   .buy--offer-div-up {
       position: relative;
       top: -68px;
       padding: 0px 24px;
   }
   
   .buy--offer-icon {
       font-size: 20px;
       background-color: #0F49C0;
       color: #fff;
       padding: 12px;
       border-radius: 100%;
       margin-right: 12px;
   }
   
   .buy--offer-div-btn1 {
       position: relative;
       background: rgba(15, 73, 192, 0.05);
       color: #0F49C0;
       border-radius: 8px;
       padding: 12px;
       border: none;
       left: -178px;
       bottom: 0px;
   }
   
   .buy--offer-div-btn2 {
       position: relative;
       right: -265px;
       bottom: 0px;
       background: #FA9334;
       color: #fff;
       border-radius: 8px;
       padding: 12px;
       border: none;
   }
 } 

