 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .roadmap {
    margin: 10px 20px;
}

.roadmap--col1 {

}

.roadmap--t1 {
    color: #141414;
}

.roadmap--t2 {
    color: #141414;
    font-size: 48px;
    line-height: 70px;

}

.roadmap--btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.roadmap--btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.roadmap--btn1:active {
  border: 5px solid #2C3C5D;
}

.roadmap--btn2 {
    border: none;
    background: #fff;
}

.roadmap--col2 {
  margin-top: 50px;
}


.roadmap--accordions {
    margin-top: 12px;
}

.roadmap--accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .roadmap--accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .roadmap--panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .roadmap--panel-text{
    width: 90%;
    line-height: 32px;
  }    

  .roadmap--section {
    background: rgba(250, 147, 52, 0.02);
    border-radius: 20px;
    padding: 32px;
    margin-top: 100px;
  }


  .roadmap--section-t1 {
    font-size: 40px;
    color: #141414;
    text-align: center;
    margin-top: -12px;
  }

  .roadmap--section-t2 {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: 0px 0px;
  }

  .roadmap--section-btn {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px;
    width: 200px;
    margin-left: 20%;
    margin-top: 24px;
  }

  .roadmap--section-btn:hover {
    border: 3px solid #2C3C5D;
    background-color: #fff;
    color: #0D0D2B;
  }
  
  .roadmap--section-btn:active {
    border: 5px solid #2C3C5D;
  }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

  .roadmap {
    margin: 10px 50px;
}

.roadmap--col1 {

}

.roadmap--t1 {
    color: #141414;
}

.roadmap--t2 {
    color: #141414;
    font-size: 48px;
    line-height: 70px;

}

.roadmap--btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.roadmap--btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.roadmap--btn1:active {
  border: 5px solid #2C3C5D;
}

.roadmap--btn2 {
    border: none;
    background: #fff;
    margin-top: 24px;
}

.roadmap--col2 {
  margin-top: 50px;
}


.roadmap--accordions {
    margin-top: 12px;
}

.roadmap--accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .roadmap--accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .roadmap--panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .roadmap--panel-text{
    width: 90%;
    line-height: 32px;
  }    

  .roadmap--section {
    background: rgba(250, 147, 52, 0.02);
    border-radius: 20px;
    padding: 32px;
    margin-top: 100px;
  }


  .roadmap--section-t1 {
    font-size: 40px;
    color: #141414;
    text-align: center;
    margin-top: -12px;
  }

  .roadmap--section-t2 {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: 0px 50px;
  }

  .roadmap--section-btn {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px;
    width: 200px;
    margin-left: 27%;
    margin-top: 24px;
  }

  .roadmap--section-btn:hover {
    border: 3px solid #2C3C5D;
    background-color: #fff;
    color: #0D0D2B;
  }
  
  .roadmap--section-btn:active {
    border: 5px solid #2C3C5D;
  }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {


  .roadmap {
    margin: 10px 50px;
}

.roadmap--col1 {

}

.roadmap--t1 {
    color: #141414;
}

.roadmap--t2 {
    color: #141414;
    font-size: 48px;
    line-height: 70px;

}

.roadmap--btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.roadmap--btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.roadmap--btn1:active {
  border: 5px solid #2C3C5D;
}

.roadmap--btn2 {
    border: none;
    background: #fff;
}

.roadmap--col2 {
  margin-top: 50px;
}


.roadmap--accordions {
    margin-top: 12px;
}

.roadmap--accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .roadmap--accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .roadmap--panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .roadmap--panel-text{
    width: 90%;
    line-height: 32px;
  }    

  .roadmap--section {
    background: rgba(250, 147, 52, 0.02);
    border-radius: 20px;
    padding: 32px;
    margin-top: 100px;
  }


  .roadmap--section-t1 {
    font-size: 40px;
    color: #141414;
    text-align: center;
    margin-top: -12px;
  }

  .roadmap--section-t2 {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: 0px 100px;
  }

  .roadmap--section-btn {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px;
    width: 200px;
    margin-left: 35%;
    margin-top: 24px;
  }

  .roadmap--section-btn:hover {
    border: 3px solid #2C3C5D;
    background-color: #fff;
    color: #0D0D2B;
  }
  
  .roadmap--section-btn:active {
    border: 5px solid #2C3C5D;
  }
 
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

  .roadmap {
    margin: 50px 100px;
}

.roadmap--col1 {

}

.roadmap--t1 {
    color: #141414;
}

.roadmap--t2 {
    color: #141414;
    font-size: 48px;
    line-height: 70px;

}

.roadmap--btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.roadmap--btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.roadmap--btn1:active {
  border: 5px solid #2C3C5D;
}

.roadmap--btn2 {
    border: none;
    background: #fff;
}

.roadmap--col2 {

}


.roadmap--accordions {
    margin-top: 12px;
}

.roadmap--accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .roadmap--accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .roadmap--panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .roadmap--panel-text{
    width: 90%;
    line-height: 32px;
  }    

  .roadmap--section {
    background: rgba(250, 147, 52, 0.02);
    border-radius: 20px;
    padding: 32px;
    margin-top: 100px;
  }


  .roadmap--section-t1 {
    font-size: 40px;
    color: #141414;
    text-align: center;
    margin-top: -12px;
  }

  .roadmap--section-t2 {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: 0px 100px;
  }

  .roadmap--section-btn {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px;
    width: 200px;
    margin-left: 42%;
    margin-top: 24px;
  }

  .roadmap--section-btn:hover {
    border: 3px solid #2C3C5D;
    background-color: #fff;
    color: #0D0D2B;
  }
  
  .roadmap--section-btn:active {
    border: 5px solid #2C3C5D;
  }
 
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .roadmap {
    margin: 50px 100px;
}

.roadmap--col1 {

}

.roadmap--t1 {
    color: #141414;
}

.roadmap--t2 {
    color: #141414;
    font-size: 48px;
    line-height: 70px;

}

.roadmap--btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.roadmap--btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.roadmap--btn1:active {
  border: 5px solid #2C3C5D;
}

.roadmap--btn2 {
    border: none;
    background: #fff;
}

.roadmap--col2 {

}


.roadmap--accordions {
    margin-top: 12px;
}

.roadmap--accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .roadmap--accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .roadmap--panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .roadmap--panel-text{
    width: 90%;
    line-height: 32px;
  }    

  .roadmap--section {
    background: rgba(250, 147, 52, 0.02);
    border-radius: 20px;
    padding: 32px;
    margin-top: 100px;
  }


  .roadmap--section-t1 {
    font-size: 40px;
    color: #141414;
    text-align: center;
    margin-top: -12px;
  }

  .roadmap--section-t2 {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    margin: 0px 300px;
  }

  .roadmap--section-btn {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px;
    width: 200px;
    margin-left: 42%;
    margin-top: 24px;
  }

  .roadmap--section-btn:hover {
    border: 3px solid #2C3C5D;
    background-color: #fff;
    color: #0D0D2B;
  }
  
  .roadmap--section-btn:active {
    border: 5px solid #2C3C5D;
  }
  
 } 

