/* Extra small devices (phones, 600px and down*/
@media only screen and (max-width: 600px) {
    body {
        overflow-x: hidden;
    }
    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    #home-side  {
        display: none;
    }
    
    .home-side {
        margin-top: 50px;
        position: relative;
        right: 1%;
        border-radius: 12px;
        padding: 10px 0px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }
    
    .home-side-t {
        padding: 20px 16px;
        border-bottom: 1px solid #00000030;
        margin-bottom: -16px;
        cursor: pointer;
    }
    
    .home-side-t:hover {
        background: linear-gradient(90deg, rgba(44, 60, 93, 0.1) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    }
    
    .home-side-icon {
        font-size: 18px;
        color: #000000;
        margin-right: 16px;
    }
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }

    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }


    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 30px 10px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
        padding-right: 12px;
    }
    
    .home--lg-1 {
        width: 140px;
        height: 60px;
        margin-top: -8px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        position: relative;
        left: 8px;
        top: -34px;
        font-size: 50px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        color: #0D0D2B;
        margin-top: 100px;
    }
    
    .home--btn-1 {
        position: relative;
        left: 24px;
        width: 80%;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-top: 0px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 0px;
        margin-top: 0;
        display: grid;
        grid-template-columns: auto;
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 10px;
        margin-bottom: 24px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 10px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 30%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        width: 150px;
        height: 150px;
        /* margin-left: 33%; */
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  24px; 
        padding-right: 24px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 900px;
        display: block;
        margin-left: -100px;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: -50px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: 10px;
        margin-right: -100px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        display: none;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }

    #home-notifications {
        display: none;
    }
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }
    
    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 100px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 100px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    
    .accordions {
        margin-top: 50px;
        margin-bottom: 12px;
    }
    
    
    .accordion {
        background-color: #ffffff;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        transition: 0.4s;
        border-bottom: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-top: none;
      }
      
      .active, .accordion:hover {
        background-color: #f8f8f8;
      }
      
      .accordion:after {
        content: '\002B';
        color: #777;
        font-weight: bold;
        float: right;
      }
      .active:after {
        content: "\2212";
      }
      
      .panel {
        display: flex;
        padding: 0px 18px;
        background-color: white;
        font-size: 14px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        text-align: left;
        gap: 32px;
      }
      .panel-text{
        width: 90%;
      }    
}

/*  Small devices (portrait tablets and large phones, 600px and up*/
@media only screen and (min-width: 600px) {
    body {
        overflow-x: hidden;
    }
    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }

    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 30px 10px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
        padding-right: 32px;
    }
    
    .home--lg-1 {
        width: 140px;
        height: 60px;
        margin-top: -8px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        font-size: 48px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        color: #0D0D2B;
        margin-top: 124px;
    }
    
    .home--btn-1 {
        width: 250px;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-left: 32%;
        margin-top: 24px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 24px;
        margin-top: 0;
        display: grid;
        grid-template-columns: auto;
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 10px;
        margin-bottom: 24px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 10px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 38%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        /* margin-left: 38%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {        
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  32px; 
        padding-right: 32px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 900px;
        display: block;
        margin-left: -100px;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: 0px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: 10px;
        margin-right: -100px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        margin-left: -200px;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }

    #home-notifications {
        display: none;
    }
    
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
    
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }

    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 100px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 100px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    
    .accordions {
        margin-top: 50px;
        margin-bottom: 12px;
    }
    
    
    .accordion {
        background-color: #ffffff;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        transition: 0.4s;
        border-bottom: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-top: none;
      }
      
      .active, .accordion:hover {
        background-color: #f8f8f8;
      }
      
      .accordion:after {
        content: '\002B';
        color: #777;
        font-weight: bold;
        float: right;
      }
      .active:after {
        content: "\2212";
      }
      
      .panel {
        display: flex;
        padding: 0px 18px;
        background-color: white;
        font-size: 14px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        text-align: left;
        gap: 32px;
      }
      .panel-text{
        width: 90%;
      }    

}

/*  Medium devices (landscape tablets 768px and up*/
@media only screen and (min-width: 768px) {
    body {
        overflow-x: hidden;
    }

    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }


    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }


    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 30px 50px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
        padding-right: 32px;
    }
    
    .home--lg-1 {
        width: 150px;
        height: 60px;
        margin-top: -8px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        font-size: 54px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        color: #0D0D2B;
        margin-top: 114px;
    }
    
    .home--btn-1 {
        width: 250px;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-left: 35%;
        margin-top: 24px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 52px;
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 50px;
        margin-bottom: 44px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 50px;
        margin-bottom: 44px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 41%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        /* margin-left: 41%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  32px; 
        padding-right: 32px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 900px;
        display: block;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: 100px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: 10px;
        margin-right: -100px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        margin-left: -30px;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }


    #home-notifications {
        display: none;
    }
    
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
    
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }

    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 100px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 100px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    
    .accordions {
        margin-top: 50px;
        margin-bottom: 12px;
    }
    
    
    .accordion {
        background-color: #ffffff;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        transition: 0.4s;
        border-bottom: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-top: none;
      }
      
      .active, .accordion:hover {
        background-color: #f8f8f8;
      }
      
      .accordion:after {
        content: '\002B';
        color: #777;
        font-weight: bold;
        float: right;
      }
      .active:after {
        content: "\2212";
      }
      
      .panel {
        display: flex;
        padding: 0px 18px;
        background-color: white;
        font-size: 14px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        text-align: left;
        gap: 32px;
      }
      .panel-text{
        width: 90%;
      }    


}

/*  Large devices (laptops/desktops, 992px and up*/
@media only screen and (min-width: 992px) {
    body {
        overflow-x: hidden;
    }
    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }


    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }


    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 30px 120px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
    }
    
    .home--lg-1 {
        width: 150px;
        height: 60px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        font-size: 64px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: center;
        margin-left: 90px;
        margin-right: 90px;
        color: #0D0D2B;
        margin-top: 114px;
    }
    
    .home--btn-1 {
        width: 250px;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-left: 38%;
        margin-top: 24px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 50px;
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 80px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 80px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 24%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        /* margin-left: 24%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  32px; 
        padding-right: 40px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 900px;
        display: block;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: 100px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: -50px;
        margin-right: 10px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        margin-left: -150px;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }


    #home-notifications {
        display: none;
    }
    
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
    
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }

    
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }

    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 80px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 100px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    

}

/*  Extra Large devices (laptops/desktops, 1200px and up*/
@media only screen and (min-width: 1200px) {
    body {
        overflow-x: hidden;
    }
    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }

    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }



    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 30px 120px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
    }
    
    .home--lg-1 {
        width: 150px;
        height: 60px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        font-size: 64px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: center;
        margin-left: 250px;
        margin-right: 250px;
        color: #0D0D2B;
        margin-top: 104px;
    }
    
    .home--btn-1 {
        width: 250px;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-left: 40%;
        margin-top: 24px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 100px;
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 100px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 100px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 35%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        /* margin-left: 35%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  100px; 
        padding-right: 40px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 900px;
        display: block;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: 100px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: 50px;
        margin-right: -100px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        margin-left: -0px;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }


    #home-notifications {
        display: none;
    }

    .notifications {

    }
    
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
    
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }

    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 100px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 100px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    
}

/*  Extra Large devices (laptops/desktops, 1500px and up*/
@media only screen and (min-width: 1500px) {
    body {
        overflow-x: hidden;
    }
    .cube {
        position: absolute;
        color: #fa9334;
        font-size: 6px;
        top: 80vh;
        left: 45vw;
        width: 6px;
        height: 6px;
        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: cube 12s ease-in forwards infinite;
        z-index: -1;
      }
      /* .cube:nth-child(2n) {
        border-color: grey ;
      } */
      .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25vw;
        top: 40vh;
      }
      .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75vw;
        top: 50vh;
      }
      .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90vw;
        top: 10vh;
      }
      .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10vw;
        top: 85vh;
      }
      .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50vw;
        top: 10vh;
      }
      @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(360deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
    
    
    
    #sidebar {
        display: none;
    }
    
    #join {
        display: none;
    }
    
    .home--sidebar {
        margin: 0;
        width: 400px;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .home--sidebar-hero {
        margin-top: 80px;
        margin-bottom: 18px;
        width: 100%;
        border: 0.5px solid rgba(151, 151, 151, 0.4);
        border-radius: 10px;
    }
    
    .home--sidebar-t3 {
        font-size: 14px;
        text-align: center;
    }
    
    .home--sidebar-t4 {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--sidebar-t5 {
        margin: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        padding: 18px;
        border: none;
        color: #fff;
        background: #FA9334;;
    }
    
    .home--sidebar-t5:hover {
        opacity: 0.7;
    }
    
    .home--sidebar-icon1 {
        font-size: 32px;
        margin-top: 24px;
        margin-right: 18px;
    }
    
    .home--sidebar-t6 {
        position: relative;
        font-size: 14px;
        top: -16px;
    }
    
    .home--sidebar-t7 {
        position: relative;
        font-size: 12px;
        top: -20px;
        left: 37px;
    }
    
    .home--sidebar-t8 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        top: 19px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50px;
    }
    
    .home--sidebar-t9 {        
        display: inline-block;
        position: relative;
        font-size: 12px;
        top: 5px;
        right: 25px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70px;
    }
    
    .home--sidebar-icon2 {
        font-size: 22px;
        margin-top: 6px;
    }

    .home--sidebar2 {
        margin: 0;
        width: 400px;
        margin-top: 50px;
        height: 89%;
        position: absolute;
        right: 0;
        padding: 30px 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--sidebar2-left {
        display: flex;
        flex-direction: row;
    }
    
    .home--sidebar2-img {
        background-color: #d52942;
        border: 3px solid #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .home--sidebar2-t1 {
        margin-top: 10px;
    }
    
    .home--sidebar2-t2 {
        color: rgba(44, 60, 93, 0.8);
        margin-top: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }



    
    #add-funds {
        display: none;
    }
    
    .home--add-funds {
        margin: 0;
        width: 400px;
        height: 450px;
        position: absolute;
        right: 0;
        padding: 20px 25px;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        z-index: 2000;
    }
    
    .home--add-funds-t1 {
        margin-top: 0px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
    
    .home--add-funds-img {
        width: 180px;
        height: 180px;
        margin-left: 25%;
    }
    
    .home--add-funds-t2 {
        color: #141414;
        line-height: 26px;
        text-align: center;
    }
    
    .home--add-funds-input {
        width: 300px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 12px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        text-overflow: ellipsis;
    }
    
    .home--add-funds-icon {
        font-size: 16px;
        color: #fff;
        background: #FA9334;
        border-radius: 8px;
        padding: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
    }
    
    .home--add-funds-icon:hover {
        opacity: 0.7;
    }
    
    
    
    
    
    .home--header {
        height: 14vh;
        padding: 50px 200px;
        /* background-color: #020E2710; */
        /* box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1); */
    }
    
    .home--header-l {
        
    }
    
    .home--header-r {
        position: relative;
        display: flex;
    }
    
    .home--lg-1 {
        width: 150px;
        height: 60px;
    }
    
    .home--lg-3 {
        position: relative;
        width: 45px;
        height: 45px;
        border: 2px solid #fff;
        border-radius: 100%;
        margin-top: 4px;
        background-color: #d52942;
        right: -50px;
    }
    
    .home--t1 {
        padding-right: 24px;
    }
    
    .home--icon-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: 26px;
    }

    .home--icon-1-1 {
        position: relative;
        font-size: 36px;
        top: 8px;
        left: -2px;
    }
    
    .home--hero {
        height: 84vh;
    
    }
    
    .home--t2 {
        font-size: 64px;
        line-height: 75px;
        color: #0D0D2B;
        text-align: center;
        margin-left: 380px;
        margin-right: 380px;
        color: #0D0D2B;
        margin-top: 124px;
    }
    
    .home--btn-1 {
        width: 250px;
        background-color: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        padding: 20px 55px;
        border: none;
        margin-left: 40%;
        margin-top: 54px;
    }
    
    .home--btn-1:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
    }
    
    .home--btn-1:active {
        border: 5px solid #2C3C5D;
    }
    
    .section1 {
        margin: 200px;
        margin-top: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    
    .home--row {

    }
    
    .home--col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #FAFAFA;
        color: #020E27;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 40px;
        padding: 105px 100px;
    }
    
    .home--col-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        background-color: #020E27;
        color: #fff;
        box-shadow: 0px 5px 10px #FFFFFF;
        border-radius: 40px;
        padding: 105px 100px;
    }
    
    .home--col-icon1 {
        font-size: 60px;
        /* margin-left: 35%; */
        width: 150px;
        height: 150px;
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #020E27;
        color: #fff;
        border-radius: 100%;
    }
    
    .home--col-icon2 {
        font-size: 60px;
        /* margin-left: 35%; */
        width: 150px;
        height: 150px;
        padding: 45px;
        background-color: #fff;
        color: #020E27;
        border-radius: 100%;
    }
    
    .home--t3 {
        text-align: center;
        font-size: 32px;
    }
    
    .home--t4 {
        text-align: center;
        line-height: 30px;
    }
    
    .home--col-3 {
        padding: 20px  100px; 
        padding-right: 40px;
    }
    
    .home--col-4 {
        background-color: #020E27;
        padding: 00px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        height: 700px;
    }
    
    .home--sec2-img-1 {
        z-index: 1000;
        width: 1000px;
        display: block;
    }
    
    .home--sec2-img-2 {
        z-index: 1000;
        width: 650px;
        margin-left: 200px;
        display: none;
    }
    
    .home--sec2-img-3 {
        z-index: 1000;
        width: 450px;
        margin-left: 200px;
        margin-right: -100px;
        margin-top: 50px;
    }
    
    .home--sec2-img-4 {
        z-index: 1000;
        width: 450px;
        margin-top: 50px;
        margin-left: -0px;
    }
    
    .home--sec2-img-5 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: 220px;
    }
    
    .home--sec2-img-6 {
        position: relative;
        z-index: 1000;
        width: 650px;
        top: -450px;
        left: 300px;
    }
    
    #Slide_1 {
        display: block;
    }
    
    #Slide_2 {
        display: none;
    }
    
    #Slide_3 {
        display: none;
    }
    
    #Slide_4 {
        display: none;
    }


    #home-notifications {
        display: none;
    }

    .notifications {

    }
    
    
    
    .home--t4b {
        font-size: 32px;
        color: #141414;
    }
    
    .home--t4c {
        color: #14141480;
        margin-bottom: 32px;
    }
    
    .home--t4d {
        line-height: 30px;
        cursor: pointer;
    }
    
    .home--t4d:hover {
        opacity: 0.6;
    }
    
            
    .home--slides:focus {
        font-weight: 600;
    }
    
    
    
    
    .join-wait {
        width: 450px;
        padding: 24px;
        border-radius: 20px;
    }
    
    .join-wait--t1 {
        font-size: 24px;
        font-weight: 700;
        color: #141414;
        text-align: center;
    }

    .join-wait--t2 {
        color: #141414;
        text-align: center;
        margin-top: -12px;
        margin-bottom: 24px;
    }

    .join-wait--input {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px 22px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        background: #ffffff;
    }
    
    .join-wait--btn {
        width: 100%;
        padding: 16px;
        background-color: #2C3C5D;
        color: #fff;
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .join-wait--btn:hover {
        border: 3px solid #2C3C5D;
        background-color: #fff;
        color: #0D0D2B;
      }
      
      .join-wait--btn:active {
        border: 5px solid #2C3C5D;
      }
    
    .join-wait--t3 {
        color: #008000;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 8px;
        padding: 16px;
        text-align: center;
    }

    .join-wait--icon {
        font-size: 24px;
    }

    .join-wait--icon:hover {
        opacity: 0.7;
    }
    
    
    #join-wait--t3 {
        display: none;
    }
    
    
    
    
    
    
    
    
    .home--footer {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        margin: 200px;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    
    .home--t5 {
        font-weight: 600;
        margin-bottom: 24px;
    }
    
    .home--t6 {
        margin-bottom: 24px;
    }
    
    .home--t6-2 {
        padding-top: 12px;
        padding-bottom: 28px;
    }
    
    .home--t6-2-2 {
        padding-left: 18px;
        font-size: 16px;
    }
    
    .home--footer-foot {
        margin: 200px;
        margin-top: -50px;
        margin-bottom: 200px;
    }
    
    .home--footer-icons {
        margin-top: -6px;
    }
    
    .home--footer-icon {
        font-size: 24px;
        background: #FA933410;
        color: #FA9334;
        padding: 18px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon:hover {
        background: #FA933440;
    }
    
    .home--footer-icons2 {
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 20px;
    }
    
    .home--footer-icon2 {
        font-size: 20px;
        background: #FA933410;
        color: #FA9334;
        padding: 10px;
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .home--footer-icon2:hover {
        background: #FA933440;
    }
    
}