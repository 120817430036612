 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .offer--details {
        overflow: scroll;
        margin: 50px 10px;
        display: block;
    }
    
    .offer--details-row {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-div1 {
        width: 750px;
        height: 450px;
        background-color: #2C3C5D;
        color: #fff;
        padding: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .offer--details-div2 {
        width: 750px;
        height: 450px;
        padding: 36px;
        background: #FFFFFF;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-icon {
        font-size: 20px;
        padding: 16px;
        background-color: #FA9334;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .offer--details-t1 {
        display: inline-block;
        position: relative;
        top: -8px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-t2 {
        position: relative;
        top: -24px;
        left: 68px;
        font-size: 12px;
    }
    .offer--details-t3 {
        position: relative;
        top: 4px;
        right: -46px;
        font-weight: 400;
        text-align: right;
    }
    
    .offer--details-t4 {
        position: relative;
        top: 6px;
        font-size: 12px;
        text-align: right;
    }
    
    .offer--details-t5 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t6 {
        font-weight: 600;
    }
    
    .offer--details-t7 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t8 {
        font-weight: 600;
    }
    
    .offer--details-btn1 {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-top: 26px;
        margin-bottom: 6px;
    }
    
    .offer--details-t9 {
        text-align: left;
    }
    
    .offer--details-icon2 {
        font-size: 20px;
        background: #2C3C5D;
        padding: 18px;
        border-radius: 100%;
        color: #fff;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-icon2:active {
        font-size: 20px;
        background: #fff;
        border: 4px solid #2C3C5D;
        padding: 16px;
        border-radius: 100%;
        color: #2C3C5D;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-input2 {
        width: 300px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 14px 24px;
        margin-top: 12px;
    }
    
    .offer--details-btn2 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
        color: #fff;
        margin-top: 24px;
    }
    
    .offer--details-t10 {
        font-size: 24px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }
    
    .offer--details-t11 {
        color: #9C9C9C;
        font-weight: 100;
    }
    
    #bank-payment {
        display: block;
    }
    
    
    #swap-2 {
        display: none;
    }
    
    
    
    .offer--details-modal {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal-icon1 {
        font-size: 18px;
        padding: 18px;
        border-radius: 100%;
        background-color: #0F49C0;
        color: #fff;
        margin-right: 16px;
    }
    
    .offer--details-modal-t1 {
        display: block;
        position: relative;
        top: -48px;
        left: 70px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modal-t2 {
        position: relative;
        top: -58px;
        left: 70px;
        font-size: 12px;
    }
    
    .offer--details-modal-t3 {
        position: relative;
        top: -10px;
        font-weight: 700;
        text-align: right;
    }
    
    .offer--details-modal-t4 {
        position: relative;
        top: -26px;
        font-size: 14px;
        text-align: right;
    }
    
    .offer--details-modal-t5 {
        font-weight: 600;
    }
    
    .offer--details-modal-t6 {
        font-weight: 500;
    }
    
    .offer--details-modal-btn1 {
        width: 100%;
        background-color: #2C3C5D;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
    
    
    #abeg-payment {
        display: block;
    }
    
    .offer--details-modall-t1 {
        display: inline-block;
        margin-top: 0px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modall-hr {
        margin-top: 28px;
    }
    
    .offer--details-modall-t2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .offer--details-modall-btn1 {
        background: rgba(151, 71, 255, 0.1);
        color: #9747FF;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .offer--details-modall-btn2 {
        background: rgba(0, 128, 0, 0.1);
        color: #008000;
        border-radius: 8px;
        padding: 12px;
        border: none;
        font-size: 14px;
    }
    
    .offer--details-modall-btn3 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 42px;
    }
    
    
    
    
    
     #payment-confirmation {
        display: block;
    }

    #buyorder-made {
        display: block;
    }

    #buyorder-accepted {
        display: block;
    }

    #buyorder-confirmed {
        display: block;
    }

    #sellorder-cancelled {
        display: block;
    }

    
    .offer--details-modal2 {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal2-icon1 {
        font-size: 40px;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        padding: 24px;
        color: rgb(0, 128, 0);
        margin-left: 38.5%;
    }
    
    .offer--details-modal2-t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    
    .offer--details-modal2-t2 {
        text-align: center;
    }
    
    .offer--details-modal2-btn1 {
        width: 220px;
        background: rgba(250, 147, 52, 0.05);
        border-radius: 8px;
        color: #FA9334;
        padding: 16px;
        margin-right: 14px;
        border: none;
        margin-top: 12px;
    }
    
    .offer--details-modal2-btn2 {
        width: 140px;
        background: rgba(44, 60, 93, 0.05);
        border-radius: 8px;
        color: #2C3C5D;
        padding: 16px;
        border: none;
        margin-top: 12px;
    }
    
    
    
    #my-notifications {
        display: none;
    }
    
    .my-notifications {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-right: 32px;
    }
    
    .my-notifications-t1 {
        margin-top: 0px;
        font-size: 20px;
        color: #141414;
    }
    
    .my-notifications-icon {
        font-size: 14px;
        background: #0F49C0;
        color: #fff;
        padding: 12px;
        border-radius: 100%;
        margin-right: 12px;
    }
    
    .my-notifications-t2 {
        font-size: 13px;
        font-size: #141414;
    }
    
    
    #rate-us {
        display: none;
    }
    
    .my-rate-us {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-top: 182px;
        margin-right: 32px;
    }
    
    .my-rate-us-x {
        font-size: 24px;
        margin-top: 4px;
    }
    
    .my-rate-us-x:hover {
        opacity: 0.7;
    }
    
    .my-rate-us-x:active {
        opacity: 0.6;
    }
    
    .my-rate-us-t1 {
        font-size: 20px;
        margin-top: 0px;
    }
    
    .my-rate-us-t2 {
        font-size: 14px;
    }
    
    .my-rate-us-icons {
        text-align: center;
        margin-top: 32px;
    }
    
    .my-rate-us-icon {
        font-size: 20px;
        background: rgba(250, 147, 52, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    
    .my-rate-us-icon:hover {
        background: rgba(250, 147, 52, 0.4);
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .offer--details {
        overflow: scroll;
        margin: 50px 50px;
        display: block;
    }
    
    .offer--details-row {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-div1 {
        width: 750px;
        height: 450px;
        background-color: #2C3C5D;
        color: #fff;
        padding: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .offer--details-div2 {
        width: 750px;
        height: 450px;
        padding: 36px;
        background: #FFFFFF;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-icon {
        font-size: 20px;
        padding: 16px;
        background-color: #FA9334;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .offer--details-t1 {
        display: inline-block;
        position: relative;
        top: -8px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-t2 {
        position: relative;
        top: -24px;
        left: 68px;
        font-size: 12px;
    }
    .offer--details-t3 {
        position: relative;
        top: 4px;
        right: -46px;
        font-weight: 400;
        text-align: right;
    }
    
    .offer--details-t4 {
        position: relative;
        top: 6px;
        font-size: 12px;
        text-align: right;
    }
    
    .offer--details-t5 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t6 {
        font-weight: 600;
    }
    
    .offer--details-t7 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t8 {
        font-weight: 600;
    }
    
    .offer--details-btn1 {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-top: 26px;
        margin-bottom: 6px;
    }
    
    .offer--details-t9 {
        text-align: left;
    }
    
    .offer--details-icon2 {
        font-size: 20px;
        background: #2C3C5D;
        padding: 18px;
        border-radius: 100%;
        color: #fff;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-icon2:active {
        font-size: 20px;
        background: #fff;
        border: 4px solid #2C3C5D;
        padding: 16px;
        border-radius: 100%;
        color: #2C3C5D;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-input2 {
        width: 300px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 14px 24px;
        margin-top: 12px;
    }
    
    .offer--details-btn2 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
        color: #fff;
        margin-top: 24px;
    }
    
    .offer--details-t10 {
        font-size: 24px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }
    
    .offer--details-t11 {
        color: #9C9C9C;
        font-weight: 100;
    }
    
    #bank-payment {
        display: block;
    }
    
    
    #swap-2 {
        display: none;
    }
    
    
    
    .offer--details-modal {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal-icon1 {
        font-size: 18px;
        padding: 18px;
        border-radius: 100%;
        background-color: #0F49C0;
        color: #fff;
        margin-right: 16px;
    }
    


    .offer--details-modal-t1 {
        display: block;
        position: relative;
        top: -48px;
        left: 70px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modal-t2 {
        position: relative;
        top: -58px;
        left: 70px;
        font-size: 12px;
    }
    
    .offer--details-modal-t3 {
        position: relative;
        top: -10px;
        font-weight: 700;
        text-align: right;
    }
    
    .offer--details-modal-t4 {
        position: relative;
        top: -26px;
        font-size: 14px;
        text-align: right;
    }
    
    .offer--details-modal-t5 {
        font-weight: 600;
    }
    
    .offer--details-modal-t6 {
        font-weight: 500;
    }
    
    .offer--details-modal-btn1 {
        width: 100%;
        background-color: #2C3C5D;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
    
    
    #abeg-payment {
        display: block;
    }
    
    .offer--details-modall-t1 {
        display: inline-block;
        margin-top: 0px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    
    .offer--details-modall-hr {
        margin-top: 28px;
    }
    
    .offer--details-modall-t2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .offer--details-modall-btn1 {
        background: rgba(151, 71, 255, 0.1);
        color: #9747FF;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .offer--details-modall-btn2 {
        background: rgba(0, 128, 0, 0.1);
        color: #008000;
        border-radius: 8px;
        padding: 12px;
        border: none;
        font-size: 14px;
    }
    
    .offer--details-modall-btn3 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 42px;
    }
    
    
    
    
    
     #payment-confirmation {
        display: block;
    }

    #buyorder-made {
        display: block;
    }

    #buyorder-accepted {
        display: block;
    }

    #buyorder-confirmed {
        display: block;
    }

    #sellorder-cancelled {
        display: block;
    }
    
    
    .offer--details-modal2 {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal2-icon1 {
        font-size: 40px;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        padding: 24px;
        color: rgb(0, 128, 0);
        margin-left: 38.5%;
    }
    
    .offer--details-modal2-t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    
    .offer--details-modal2-t2 {
        text-align: center;
    }
    
    .offer--details-modal2-btn1 {
        width: 220px;
        background: rgba(250, 147, 52, 0.05);
        border-radius: 8px;
        color: #FA9334;
        padding: 16px;
        margin-right: 14px;
        border: none;
        margin-top: 12px;
    }
    
    .offer--details-modal2-btn2 {
        width: 140px;
        background: rgba(44, 60, 93, 0.05);
        border-radius: 8px;
        color: #2C3C5D;
        padding: 16px;
        border: none;
        margin-top: 12px;
    }
    
    
    
    #my-notifications {
        display: none;
    }
    
    .my-notifications {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-right: 32px;
    }
    
    .my-notifications-t1 {
        margin-top: 0px;
        font-size: 20px;
        color: #141414;
    }
    
    .my-notifications-icon {
        font-size: 14px;
        background: #0F49C0;
        color: #fff;
        padding: 12px;
        border-radius: 100%;
        margin-right: 12px;
    }
    
    .my-notifications-t2 {
        font-size: 13px;
        font-size: #141414;
    }
    
    
    #rate-us {
        display: none;
    }
    
    .my-rate-us {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-top: 182px;
        margin-right: 32px;
    }
    
    .my-rate-us-x {
        font-size: 24px;
        margin-top: 4px;
    }
    
    .my-rate-us-x:hover {
        opacity: 0.7;
    }
    
    .my-rate-us-x:active {
        opacity: 0.6;
    }
    
    .my-rate-us-t1 {
        font-size: 20px;
        margin-top: 0px;
    }
    
    .my-rate-us-t2 {
        font-size: 14px;
    }
    
    .my-rate-us-icons {
        text-align: center;
        margin-top: 32px;
    }
    
    .my-rate-us-icon {
        font-size: 20px;
        background: rgba(250, 147, 52, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    
    .my-rate-us-icon:hover {
        background: rgba(250, 147, 52, 0.4);
    }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

    .offer--details {
        margin: 0;
        overflow: scroll;
        margin-left: 90px;
        display: block;
    }
    
    .offer--details-row {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-div1 {
        width: 750px;
        height: 450px;
        background-color: #2C3C5D;
        color: #fff;
        padding: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .offer--details-div2 {
        width: 750px;
        height: 450px;
        padding: 36px;
        background: #FFFFFF;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-icon {
        font-size: 20px;
        padding: 16px;
        background-color: #FA9334;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .offer--details-t1 {
        display: inline-block;
        position: relative;
        top: -8px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-t2 {
        position: relative;
        top: -24px;
        left: 68px;
        font-size: 12px;
    }
    .offer--details-t3 {
        position: relative;
        top: 4px;
        right: -46px;
        font-weight: 400;
        text-align: right;
    }
    
    .offer--details-t4 {
        position: relative;
        top: 6px;
        font-size: 12px;
        text-align: right;
    }
    
    .offer--details-t5 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t6 {
        font-weight: 600;
    }
    
    .offer--details-t7 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t8 {
        font-weight: 600;
    }
    
    .offer--details-btn1 {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-top: 26px;
        margin-bottom: 6px;
    }
    
    .offer--details-t9 {
        text-align: left;
    }
    
    .offer--details-icon2 {
        font-size: 20px;
        background: #2C3C5D;
        padding: 18px;
        border-radius: 100%;
        color: #fff;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-icon2:active {
        font-size: 20px;
        background: #fff;
        border: 4px solid #2C3C5D;
        padding: 16px;
        border-radius: 100%;
        color: #2C3C5D;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-input2 {
        width: 300px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 14px 24px;
        margin-top: 12px;
    }
    
    .offer--details-btn2 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
        color: #fff;
        margin-top: 24px;
    }
    
    .offer--details-t10 {
        font-size: 24px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }
    
    .offer--details-t11 {
        color: #9C9C9C;
        font-weight: 100;
    }
    
    #bank-payment {
        display: block;
    }
    
    
    #swap-2 {
        display: none;
    }
    
    
    
    .offer--details-modal {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal-icon1 {
        font-size: 18px;
        padding: 18px;
        border-radius: 100%;
        background-color: #0F49C0;
        color: #fff;
        margin-right: 16px;
    }
    
    .offer--details-modal-t1 {
        display: block;
        position: relative;
        top: -48px;
        left: 70px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modal-t2 {
        position: relative;
        top: -58px;
        left: 70px;
        font-size: 12px;
    }
    
    .offer--details-modal-t3 {
        position: relative;
        top: -10px;
        font-weight: 700;
        text-align: right;
    }
    
    .offer--details-modal-t4 {
        position: relative;
        top: -26px;
        font-size: 14px;
        text-align: right;
    }
    
    .offer--details-modal-t5 {
        font-weight: 600;
    }
    
    .offer--details-modal-t6 {
        font-weight: 500;
    }
    
    .offer--details-modal-btn1 {
        width: 100%;
        background-color: #2C3C5D;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
    
    
    #abeg-payment {
        display: block;
    }
    
    .offer--details-modall-t1 {
        display: inline-block;
        margin-top: 0px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modall-hr {
        margin-top: 28px;
    }
    
    .offer--details-modall-t2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .offer--details-modall-btn1 {
        background: rgba(151, 71, 255, 0.1);
        color: #9747FF;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .offer--details-modall-btn2 {
        background: rgba(0, 128, 0, 0.1);
        color: #008000;
        border-radius: 8px;
        padding: 12px;
        border: none;
        font-size: 14px;
    }
    
    .offer--details-modall-btn3 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 42px;
    }
    
    
    
    
    
     #payment-confirmation {
        display: block;
    }

    #buyorder-made {
        display: block;
    }

    #buyorder-accepted {
        display: block;
    }

    #buyorder-confirmed {
        display: block;
    }

    #sellorder-cancelled {
        display: block;
    }
    
    
    .offer--details-modal2 {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal2-icon1 {
        font-size: 40px;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        padding: 24px;
        color: rgb(0, 128, 0);
        margin-left: 38.5%;
    }
    
    .offer--details-modal2-t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    
    .offer--details-modal2-t2 {
        text-align: center;
    }
    
    .offer--details-modal2-btn1 {
        width: 220px;
        background: rgba(250, 147, 52, 0.05);
        border-radius: 8px;
        color: #FA9334;
        padding: 16px;
        margin-right: 14px;
        border: none;
        margin-top: 12px;
    }
    
    .offer--details-modal2-btn2 {
        width: 140px;
        background: rgba(44, 60, 93, 0.05);
        border-radius: 8px;
        color: #2C3C5D;
        padding: 16px;
        border: none;
        margin-top: 12px;
    }
    
    
    
    #my-notifications {
        display: none;
    }
    
    .my-notifications {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-right: 32px;
    }
    
    .my-notifications-t1 {
        margin-top: 0px;
        font-size: 20px;
        color: #141414;
    }
    
    .my-notifications-icon {
        font-size: 14px;
        background: #0F49C0;
        color: #fff;
        padding: 12px;
        border-radius: 100%;
        margin-right: 12px;
    }
    
    .my-notifications-t2 {
        font-size: 13px;
        font-size: #141414;
    }
    
    
    #rate-us {
        display: none;
    }
    
    .my-rate-us {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-top: 182px;
        margin-right: 32px;
    }
    
    .my-rate-us-x {
        font-size: 24px;
        margin-top: 4px;
    }
    
    .my-rate-us-x:hover {
        opacity: 0.7;
    }
    
    .my-rate-us-x:active {
        opacity: 0.6;
    }
    
    .my-rate-us-t1 {
        font-size: 20px;
        margin-top: 0px;
    }
    
    .my-rate-us-t2 {
        font-size: 14px;
    }
    
    .my-rate-us-icons {
        text-align: center;
        margin-top: 32px;
    }
    
    .my-rate-us-icon {
        font-size: 20px;
        background: rgba(250, 147, 52, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    
    .my-rate-us-icon:hover {
        background: rgba(250, 147, 52, 0.4);
    }
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

    .offer--details {
        margin: 0;
        overflow: scroll;
        margin-left: 90px;
        display: block;
    }
    
    .offer--details-row {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-div1 {
        width: 750px;
        height: 450px;
        background-color: #2C3C5D;
        color: #fff;
        padding: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .offer--details-div2 {
        width: 750px;
        height: 450px;
        padding: 36px;
        background: #FFFFFF;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-icon {
        font-size: 20px;
        padding: 16px;
        background-color: #FA9334;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .offer--details-t1 {
        display: inline-block;
        position: relative;
        top: -8px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-t2 {
        position: relative;
        top: -24px;
        left: 68px;
        font-size: 12px;
    }
    .offer--details-t3 {
        position: relative;
        top: 4px;
        right: -46px;
        text-align: right;
        font-weight: 400;
    }
    
    .offer--details-t4 {
        position: relative;
        top: 6px;
        font-size: 12px;
        text-align: right;
    }
    
    .offer--details-t5 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t6 {
        font-weight: 600;
    }
    
    .offer--details-t7 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t8 {
        font-weight: 600;
    }
    
    .offer--details-btn1 {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-top: 26px;
        margin-bottom: 6px;
    }
    
    .offer--details-t9 {
        text-align: left;
    }
    
    .offer--details-icon2 {
        font-size: 20px;
        background: #2C3C5D;
        padding: 18px;
        border-radius: 100%;
        color: #fff;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-icon2:active {
        font-size: 20px;
        background: #fff;
        border: 4px solid #2C3C5D;
        padding: 16px;
        border-radius: 100%;
        color: #2C3C5D;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-input2 {
        width: 300px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 14px 24px;
        margin-top: 12px;
    }
    
    .offer--details-btn2 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
        color: #fff;
        margin-top: 24px;
    }
    
    .offer--details-t10 {
        font-size: 24px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }
    
    .offer--details-t11 {
        color: #9C9C9C;
        font-weight: 100;
    }
    
    #bank-payment {
        display: block;
    }
    
    
    #swap-2 {
        display: none;
    }
    
    
    
    .offer--details-modal {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal-icon1 {
        font-size: 18px;
        padding: 18px;
        border-radius: 100%;
        background-color: #0F49C0;
        color: #fff;
        margin-right: 16px;
    }
    
    .offer--details-modal-t1 {
        display: block;
        position: relative;
        top: -48px;
        left: 70px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modal-t2 {
        position: relative;
        top: -58px;
        left: 70px;
        font-size: 12px;
    }
    
    .offer--details-modal-t3 {
        position: relative;
        top: -10px;
        font-weight: 700;
        text-align: right;
    }
    
    .offer--details-modal-t4 {
        position: relative;
        top: -26px;
        font-size: 14px;
        text-align: right;
    }
    
    .offer--details-modal-t5 {
        font-weight: 600;
    }
    
    .offer--details-modal-t6 {
        font-weight: 500;
    }
    
    .offer--details-modal-btn1 {
        width: 100%;
        background-color: #2C3C5D;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
    
    
    #abeg-payment {
        display: block;
    }
    
    .offer--details-modall-t1 {
        display: inline-block;
        margin-top: 0px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modall-hr {
        margin-top: 28px;
    }
    
    .offer--details-modall-t2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .offer--details-modall-btn1 {
        background: rgba(151, 71, 255, 0.1);
        color: #9747FF;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .offer--details-modall-btn2 {
        background: rgba(0, 128, 0, 0.1);
        color: #008000;
        border-radius: 8px;
        padding: 12px;
        border: none;
        font-size: 14px;
    }
    
    .offer--details-modall-btn3 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 42px;
    }
    
    
    
    
    
     #payment-confirmation {
        display: block;
    }

    #buyorder-made {
        display: block;
    }

    #buyorder-accepted {
        display: block;
    }

    #buyorder-confirmed {
        display: block;
    }

    #sellorder-cancelled {
        display: block;
    }
    
    
    .offer--details-modal2 {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal2-icon1 {
        font-size: 40px;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        padding: 24px;
        color: rgb(0, 128, 0);
        margin-left: 38.5%;
    }
    
    .offer--details-modal2-t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    
    .offer--details-modal2-t2 {
        text-align: center;
    }
    
    .offer--details-modal2-btn1 {
        width: 220px;
        background: rgba(250, 147, 52, 0.05);
        border-radius: 8px;
        color: #FA9334;
        padding: 16px;
        margin-right: 14px;
        border: none;
        margin-top: 12px;
    }
    
    .offer--details-modal2-btn2 {
        width: 140px;
        background: rgba(44, 60, 93, 0.05);
        border-radius: 8px;
        color: #2C3C5D;
        padding: 16px;
        border: none;
        margin-top: 12px;
    }
    
    
    
    #my-notifications {
        display: none;
    }
    
    .my-notifications {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-right: 32px;
    }
    
    .my-notifications-t1 {
        margin-top: 0px;
        font-size: 20px;
        color: #141414;
    }
    
    .my-notifications-icon {
        font-size: 14px;
        background: #0F49C0;
        color: #fff;
        padding: 12px;
        border-radius: 100%;
        margin-right: 12px;
    }
    
    .my-notifications-t2 {
        font-size: 13px;
        font-size: #141414;
    }
    
    
    #rate-us {
        display: none;
    }
    
    .my-rate-us {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-top: 182px;
        margin-right: 32px;
    }
    
    .my-rate-us-x {
        font-size: 24px;
        margin-top: 4px;
    }
    
    .my-rate-us-x:hover {
        opacity: 0.7;
    }
    
    .my-rate-us-x:active {
        opacity: 0.6;
    }
    
    .my-rate-us-t1 {
        font-size: 20px;
        margin-top: 0px;
    }
    
    .my-rate-us-t2 {
        font-size: 14px;
    }
    
    .my-rate-us-icons {
        text-align: center;
        margin-top: 32px;
    }
    
    .my-rate-us-icon {
        font-size: 20px;
        background: rgba(250, 147, 52, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    
    .my-rate-us-icon:hover {
        background: rgba(250, 147, 52, 0.4);
    }
 
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .offer--details {
        margin: 0;
        overflow: scroll;
        margin-left: 90px;
        display: block;
    }
    
    .offer--details-row {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-div1 {
        width: 400px;
        height: 450px;
        background-color: #2C3C5D;
        color: #fff;
        padding: 36px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .offer--details-div2 {
        width: 700px;
        height: 450px;
        padding: 36px;
        background: #FFFFFF;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .offer--details-icon {
        font-size: 20px;
        padding: 16px;
        background-color: #FA9334;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .offer--details-t1 {
        display: inline-block;
        position: relative;
        top: -8px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-t2 {
        position: relative;
        top: -24px;
        left: 68px;
        font-size: 12px;
    }
    .offer--details-t3 {
        position: relative;
        top: -72.5px;
        right: -46px;
        font-weight: 400;
        text-align: right;
    }
    
    .offer--details-t4 {
        position: relative;
        top: -69.5px;
        font-size: 12px;
        text-align: right;
    }
    
    .offer--details-t5 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t6 {
        font-weight: 600;
    }
    
    .offer--details-t7 {
        font-size: 14px;
        margin-top: 36px;
    }
    
    .offer--details-t8 {
        font-weight: 600;
    }
    
    .offer--details-btn1 {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 20px;
        border: none;
        color: #fff;
        margin-top: 26px;
        margin-bottom: 6px;
    }
    
    .offer--details-t9 {
        text-align: left;
    }
    
    .offer--details-icon2 {
        font-size: 20px;
        background: #2C3C5D;
        padding: 18px;
        border-radius: 100%;
        color: #fff;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-icon2:active {
        font-size: 20px;
        background: #fff;
        border: 4px solid #2C3C5D;
        padding: 16px;
        border-radius: 100%;
        color: #2C3C5D;
        position: relative;
        top: 32px;
        left: 12px;
    }
    
    .offer--details-input2 {
        width: 275px;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 14px 24px;
        margin-top: 12px;
    }
    
    .offer--details-btn2 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
        color: #fff;
        margin-top: 24px;
    }
    
    .offer--details-t10 {
        font-size: 24px;
        font-weight: 600;
        position: relative;
        top: 12px;
    }
    
    .offer--details-t11 {
        color: #9C9C9C;
        font-weight: 100;
    }
    
    #bank-payment {
        display: block;
    }
    
    
    #swap-2 {
        display: none;
    }
    
    
    
    .offer--details-modal {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal-icon1 {
        font-size: 18px;
        padding: 18px;
        border-radius: 100%;
        background-color: #0F49C0;
        color: #fff;
        margin-right: 16px;
    }
    
    .offer--details-modal-t1 {
        display: block;
        position: relative;
        top: -48px;
        left: 70px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modal-t2 {
        position: relative;
        top: -58px;
        left: 70px;
        font-size: 12px;
    }
    
    .offer--details-modal-t3 {
        position: relative;
        top: -10px;
        font-weight: 700;
        text-align: right;
    }
    
    .offer--details-modal-t4 {
        position: relative;
        top: -26px;
        font-size: 14px;
        text-align: right;
    }
    
    .offer--details-modal-t5 {
        font-weight: 600;
    }
    
    .offer--details-modal-t6 {
        font-weight: 500;
    }
    
    .offer--details-modal-btn1 {
        width: 100%;
        background-color: #2C3C5D;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
    
    
    #abeg-payment {
        display: block;
    }
    
    .offer--details-modall-t1 {
        display: inline-block;
        margin-top: 0px;
        color: rgba(44, 60, 93, 0.8);
        font-size: 14px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    
    .offer--details-modall-hr {
        margin-top: 28px;
    }
    
    .offer--details-modall-t2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    
    .offer--details-modall-btn1 {
        background: rgba(151, 71, 255, 0.1);
        color: #9747FF;
        border-radius: 8px;
        padding: 12px;
        border: none;
        margin-right: 10px;
        font-size: 14px;
    }
    
    .offer--details-modall-btn2 {
        background: rgba(0, 128, 0, 0.1);
        color: #008000;
        border-radius: 8px;
        padding: 12px;
        border: none;
        font-size: 14px;
    }
    
    .offer--details-modall-btn3 {
        width: 100%;
        background: #2C3C5D;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 42px;
    }
    
    
    
    
    
     #payment-confirmation {
        display: block;
    }

    #buyorder-made {
        display: block;
    }

    #buyorder-accepted {
        display: block;
    }

    #buyorder-confirmed {
        display: block;
    }

    #sellorder-cancelled {
        display: block;
    }
    
    
    .offer--details-modal2 {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .offer--details-modal2-icon1 {
        font-size: 40px;
        background: rgba(0, 128, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        padding: 24px;
        color: rgb(0, 128, 0);
        margin-left: 38.5%;
    }
    
    .offer--details-modal2-t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    
    .offer--details-modal2-t2 {
        text-align: center;
    }
    
    .offer--details-modal2-btn1 {
        width: 220px;
        background: rgba(250, 147, 52, 0.05);
        border-radius: 8px;
        color: #FA9334;
        padding: 16px;
        margin-right: 14px;
        border: none;
        margin-top: 12px;
    }
    
    .offer--details-modal2-btn2 {
        width: 140px;
        background: rgba(44, 60, 93, 0.05);
        border-radius: 8px;
        color: #2C3C5D;
        padding: 16px;
        border: none;
        margin-top: 12px;
    }
    
    
    
    #my-notifications {
        display: none;
    }
    
    .my-notifications {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-right: 32px;
    }
    
    .my-notifications-t1 {
        margin-top: 0px;
        font-size: 20px;
        color: #141414;
    }
    
    .my-notifications-icon {
        font-size: 14px;
        background: #0F49C0;
        color: #fff;
        padding: 12px;
        border-radius: 100%;
        margin-right: 12px;
    }
    
    .my-notifications-t2 {
        font-size: 13px;
        font-size: #141414;
    }
    
    
    #rate-us {
        display: none;
    }
    
    .my-rate-us {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
        margin-top: 182px;
        margin-right: 32px;
    }
    
    .my-rate-us-x {
        font-size: 24px;
        margin-top: 4px;
    }
    
    .my-rate-us-x:hover {
        opacity: 0.7;
    }
    
    .my-rate-us-x:active {
        opacity: 0.6;
    }
    
    .my-rate-us-t1 {
        font-size: 20px;
        margin-top: 0px;
    }
    
    .my-rate-us-t2 {
        font-size: 14px;
    }
    
    .my-rate-us-icons {
        text-align: center;
        margin-top: 32px;
    }
    
    .my-rate-us-icon {
        font-size: 20px;
        background: rgba(250, 147, 52, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    
    .my-rate-us-icon:hover {
        background: rgba(250, 147, 52, 0.4);
    }
 } 

