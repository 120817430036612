 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .sell--offer {
        margin: 50px 20px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
        margin-top: -24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .sell--offer {
        margin: 50px 100px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {


    .sell--offer {
        margin: 50px 200px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .sell--offer {
        margin: 50px 300px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .sell--offer {
        margin: 50px 420px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 } 

 /* Extra large devices (large laptops and desktops, 1500px and up) */
 @media only screen and (min-width: 1500px) {
    .sell--offer {
        margin: 50px 520px;
    }
    
    .sell--offer-t1 {
        font-size: 24px;
        color: #141414;
        text-align: center;
        margin-bottom: 24px;
    }
    
    .sell--offer-t2 {
        text-align: left;
        margin-top: 32px;
    }
    
    .sell--offer-t3 {
        color: #FA9334;
        font-size: 14px;
        text-align: left;
    }
    
    .sell--offer-tag {
        position: relative;
        top: 56.9px;
        right: 6px;
        padding: 8px 16px;
        background: rgba(57, 57, 57, 0.06);
        border-radius: 4px;
        font-size: 14px;
    }
    
    .sell--offer-input {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
    }
    
    .sell--offer-sign {
        position: relative;
        top: -38px
    }
    
    .sell--offer-sign1 {
        font-size: 18px;
        margin-right: 20px;
    }

    .sell--offer-sign1:active {
        opacity: 0.5;
    }
    
    .sell--offer-sign2 {
        font-size: 18px;
        margin-right: 24px;
    
    }

    .sell--offer-sign2:active {
        opacity: 0.5;
    }
    
    .sell--offer-textarea {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 8px;
        padding: 12px 24px;
        width: 100%;
        height: 180px;
    }
    
    .sell--offer-btn {
        width: 100%;
        margin-top: 24px;
        background: #2C3C5D;
        color: #fff;
        border: 2px solid #2C3C5D;
        border-radius: 8px;
        padding: 16px;
    }
    
    
    
    
    .selloffer--approve-offer {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--approve-offer-t1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 0px;
    }
    
    .selloffer--approve-offer-t1b {
        position: relative;
        right: -16px;
        top: -18px;
        font-size: 10px;
        text-align: center;
        color: red;
    }
    
    .selloffer--approve-offer-icon1 {
        font-size: 18px;
        background: rgba(20, 20, 20, 0.05);
        padding: 8px;
        border-radius: 100%;
        position: relative;
        top: -58px;
        right: -16px;
    }
    
    .selloffer--approve-offer-icon2 {
        font-size: 24px;
        background: rgba(20, 20, 20, 0.05);
        padding: 10px 14px;
        border-radius: 100%;
        margin-right: 16px;
    }
    
    .selloffer--approve-offer-t2 {
        display: inline-block;
        position: relative;
        top: -12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }
    
    .selloffer--approve-offer-t3 {
        position: relative;
        top: -32px;
        left: 60px;
        font-size: 12px;
        color: #141414;
    }
    
    .selloffer--approve-offer-t4 {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        position: relative;
        top: -20px;
        right: 0px;
    }
    
    .selloffer--approve-offer-t5 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        position: relative;
        top: -40px;
        right: 0px;
        text-align: right;
    }
    
    .selloffer--approve-offer-hr {
        position: relative;
        top: 70px;
    }
    
    .selloffer--approve-offer-t6 {
        font-weight: 700;
    }
    
    
    .selloffer--approve-offer-t7 {
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        line-height: 25px;
    }
    
    .selloffer--approve-offer-btn {
        background: rgba(20, 20, 20, 0.05);
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
    }
    
    .selloffer--approve-offer-imgs {
        width: 40px;
        height: 40px;
        position: relative;
        top: 8px;
    }
    
    
    #progress2a {
        display: none;
    }
    
    #progress2b {
        display: none;
    }
    
    #progress2c {
        display: none;
    }
    
    #progress2btn {
        display: none;
    }
    
    #progress2t {
    display: none;
    }
    
    
    .selloffer--confirmation {
        width: 450px;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 32px;
    }
    
    .selloffer--confirmation-icon1 {
        font-size: 60px;
        background: rgba(216, 62, 62, 0.05);
        color: #D83E3E;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon2 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        padding: 30px;
        border-radius: 100%;
        margin-left: 35%;
    }
    
    .selloffer--confirmation-icon3 {
        font-size: 60px;
        background: rgba(0, 128, 0, 0.05);
        color: #008000;
        border-radius: 12px;
        padding: 12px 30px;
        margin-left: 23%;
    }
    
    .selloffer--confirmation-t1 {
        text-align: center;
        font-size: 24px;
    }
    
    .selloffer--confirmation-t2 {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    
    .selloffer--confirmation-btn1 {
        width: 100%;
        background: #2C3C5D;
        color: #fff;
        border-radius: 8px;
        border: none;
        padding: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .selloffer--confirmation-btn2 {
        width: 100%;
        background: #fff;
        color: #2C3C5D;
        border-radius: 8px;
        border: none;
        padding: 16px;
    }
    
    .selloffer--confirmation-btn2:active {
        background: #2C3C5D;
        color: #fff;
    }
 }