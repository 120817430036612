 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .settings--sidebar {
    margin-left: 32px;
    margin-top: 50px;
    width: 300px;
}

.settings--sidebar-t1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-bottom: 24px;
    margin-left: 20px;
    margin-top: 24px;
}

.settings--sidebar-t2 {
    color: #141414
}

.settings--sidebar-icon {
    font-size: 16px;
    background: rgba(44, 60, 93, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 16px;
}

.settings--sidebar-div {
    margin-bottom: 24px;
    cursor: pointer;
}

.settings--sidebar-div:hover {
    background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-radius: 8px;
}

.settings--sidebar-div:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

.scripted:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

#settings-side {
  display: none;
}

.settings--side-bar {
  font-size: 26px;
  margin-left: 32px;
}







.settings--profile {
    margin-left: 20px;
    margin-top: 50px;
}

.settings--profile-col1 {
    padding: 0px 55px;
    margin-top: -24px;
}

.settings--profile-col2 {
    padding: 0px 55px;
    margin-bottom: 65px;
}

.settings--profile-div1 {
    margin-bottom: 44px;
}

.settings--profile-div2 {
    margin-bottom: 24px;
}

.settings--profile-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--profile-btn1 {
    width: 100px;
    font-size: 12px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-top: -64px;
}

.settings--profile-t2 {
    margin-bottom: 18px;
}

.settings--profile-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.settings--profile-icon {
    color: #0F49C0;
    background: rgba(15, 73, 192, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 18px;
}

.settings--profile-btn2 {
    width: 85px;
    font-size: 12px;
    background: #0F49C0;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 8px;
}

.settings--profile-btn3 {
    width: 100%;
    background: #2C3C5D;
    opacity: 0.1;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    margin-bottom: 84px;
    border: none;
}

.settings--profile-img {
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    padding: 70px 70px;
    margin-top: 50px;
}

.settings--profile-t3 {
    margin-top: 62px;
    font-size: 22px;
    font-weight: 700;
}

.settings--profile-t3-2 {
    margin-top: -16px;
    font-size: 12px;
}

.settings--profile-banner {
    margin-top: 44px;
    margin-bottom: 84px;
    border-radius: 10px;
    background-color: #F7F8F9;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    height: 200px;
    width: 200px;
    z-index: -5;
}

.settings--profile-banner-list {
  margin-top: 24px;
  z-index: 500;
}

.settings--profile-banner-list-color1 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: #F7F8F9;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color1:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color2 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: green;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color2:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color3 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: blue;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color3:active {
  opacity: 0.5;
}


.settings--profile-banner-list-color4 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: yellow;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color4:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color5 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: orange;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color5:active {
  opacity: 0.5;
}


#s-n {
    display: none;
}

#s-a {
    display: none;
}

#s-b {
    display: none;
}


.settings--notification {
    margin-left: 30px;
    margin-top: 50px;
}

.settings--notification-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--notification-t2 {
    margin-top: -12px;
}

.settings--notification-t2-t {
  position: relative;
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  font-weight: 600;
  top: 5px;
}

.settings--notification-tions {
    margin-top: 32px;
    margin-bottom: 50px;
    width: 370px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 16px;
    padding: 26px;
}

.settings--notification-hr {
    width: 113.5%;
    border: 1px solid rgba(20, 20, 20, 0.05);
    margin-left: -24px;
}

.settings--notification-checkbox {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 24px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-checkbox-2 {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 44px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-t3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -42px;
    margin-left: 64px;
}

.settings--notification-t3-3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 64px;
    margin-top: -42px;
}

.settings--notification-t4 {
    margin-top: -8px;
    margin-left: 64px;
}

.settings--notification-t4 {

}

.settings--notification-btn {
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
    padding: 16px;
    color: #fff;
    width: 160px;
    margin-bottom: 50px;

}














.settings--account {
    margin-left: 30px;
    width: ;
}

.settings--account-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--account-t2 {
    margin-top: -12px;
}

.settings--account-accordions {
    margin-top: 52px;
}

.settings--account-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 90%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .settings--account-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .settings--account-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .settings--account-panel-text{
    margin-right: 45%;
    line-height: 32px;
  }    

  .settings--account-select {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .settings--account-btn {
    color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    width: 160px;
  }









  

  .settings--bank {
    margin-left: 30px;
    margin-top: 50px;
  }

  .settings--bank-div {
    overflow-y: scroll;
    width: 360px;
    height: 400px;
    padding: 30px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 10px 40px rgba(20, 20, 20, 0.2);
  }

  .settings--bank-header {
    margin-bottom: 80px;
  }

  .settings--bank-img1 {
    width: 50px;
    height: 50px;
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    margin-right: 16px;
  }

  .settings--bank-t1 {
    color: #141414;
  }

  .settings--bank-t2 {
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }

  .settings--bank-body {
    margin-top: 20px;
  }

  .settings--bank-img2 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .settings--bank-t3 {
    position: relative;
    font-size: 14px;
    top: -13px;
  }

  .settings--bank-t4 {
    position: relative;
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    left: 82px;
    top: -29px;
  }

  .settings--bank-icon1 {
    font-size: 18px;
    margin-top: -40px;
  }

  .settings--bank-icon2 {
    position: relative;
    font-size: 20px;
    background-color: #2C3C5D;
    color: #fff;
    padding: 18px;
    border-radius: 100%;
    bottom: -100px;
  }

  #s-b-delete {
    display: none;
  }

  #s-b-delete-confirm {
    display: none;
  }

  .s-b-delete {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-icon1 {
    color: #D83E3E;
    font-size: 54px;
    background: rgba(216, 62, 62, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }






  .s-b-delete-confirm {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-confirm-icon2 {
    color: #008000;
    font-size: 54px;
    background: rgba(0, 128, 0, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-confirm-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-confirm-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-confirm-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-confirm-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }


  .s-b-add-account {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-add-account-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-add-account-t2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .s-b-add-account-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 14px;
  }

  .s-b-add-account-input2 {
    padding: 12px;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-top: 0px;
    color: #979797;
  }

  .s-b-add-account-btn {
    background-color: #2C3C5D;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px;
    margin-top: 6px;
  }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

  .settings--sidebar {
    margin-left: 32px;
    margin-top: 50px;
    width: 300px;
}

.settings--sidebar-t1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-bottom: 24px;
    margin-left: 20px;
    margin-top: 24px;
}

.settings--sidebar-t2 {
    color: #141414
}

.settings--sidebar-icon {
    font-size: 16px;
    background: rgba(44, 60, 93, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 16px;
}

.settings--sidebar-div {
    margin-bottom: 24px;
    cursor: pointer;
}

.settings--sidebar-div:hover {
    background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-radius: 8px;
}

.settings--sidebar-div:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

.scripted:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

#settings-side {
  display: none;
}

.settings--side-bar {
  font-size: 26px;
  margin-left: 32px;
}







.settings--profile {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
}

.settings--profile-col1 {
    padding: 0px 55px;
    margin-top: -24px;
}

.settings--profile-col2 {
    padding: 0px 55px;
}

.settings--profile-div1 {
    margin-bottom: 44px;
}

.settings--profile-div2 {
    margin-bottom: 24px;
}

.settings--profile-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--profile-btn1 {
    width: 100px;
    font-size: 12px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-top: -64px;
}

.settings--profile-t2 {
    margin-bottom: 18px;
}

.settings--profile-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.settings--profile-icon {
    color: #0F49C0;
    background: rgba(15, 73, 192, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 18px;
}

.settings--profile-btn2 {
    width: 85px;
    font-size: 12px;
    background: #0F49C0;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 8px;
}

.settings--profile-btn3 {
    width: 100%;
    background: #2C3C5D;
    opacity: 0.1;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    margin-bottom: 84px;
    border: none;
}

.settings--profile-img {
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    padding: 70px 70px;
    margin-top: 50px;
}

.settings--profile-t3 {
    margin-top: 62px;
    font-size: 22px;
    font-weight: 700;
}

.settings--profile-t3-2 {
    margin-top: -16px;
    font-size: 12px;
}

.settings--profile-banner {
    margin-top: 44px;
    border-radius: 10px;
    background-color: #F7F8F9;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    height: 200px;
    width: 200px;
}

.settings--profile-banner-list {
  margin-top: 24px;
}

.settings--profile-banner-list-color1 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: #F7F8F9;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color1:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color2 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: green;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color2:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color3 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: blue;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color3:active {
  opacity: 0.5;
}


.settings--profile-banner-list-color4 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: yellow;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color4:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color5 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: orange;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color5:active {
  opacity: 0.5;
}


#s-n {
    display: none;
}

#s-a {
    display: none;
}

#s-b {
    display: none;
}


.settings--notification {
    margin-left: 110px;
    margin-top: 50px;
}

.settings--notification-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--notification-t2 {
    margin-top: -12px;
}

.settings--notification-t2-t {
  position: relative;
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  font-weight: 600;
  top: 5px;
}

.settings--notification-tions {
    margin-top: 32px;
    margin-bottom: 50px;
    width: 370px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 16px;
    padding: 26px;
}

.settings--notification-hr {
    width: 113.5%;
    border: 1px solid rgba(20, 20, 20, 0.05);
    margin-left: -24px;
}

.settings--notification-checkbox {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 24px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-checkbox-2 {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 44px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-t3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -42px;
    margin-left: 64px;
}

.settings--notification-t3-3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 64px;
    margin-top: -42px;
}

.settings--notification-t4 {
    margin-top: -8px;
    margin-left: 64px;
}

.settings--notification-t4 {

}

.settings--notification-btn {
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
    padding: 16px;
    color: #fff;
    width: 160px;
    margin-bottom: 50px;

}














.settings--account {
    margin: 70px;
}

.settings--account-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--account-t2 {
    margin-top: -12px;
}

.settings--account-accordions {
    margin-top: 52px;
}

.settings--account-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 90%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .settings--account-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .settings--account-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .settings--account-panel-text{
    margin-right: 45%;
    line-height: 32px;
  }    

  .settings--account-select {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .settings--account-btn {
    color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    width: 160px;
  }









  

  .settings--bank {
    margin-left: 150px;
    margin-top: 50px;
  }

  .settings--bank-div {
    overflow-y: scroll;
    width: 360px;
    height: 400px;
    padding: 30px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 10px 40px rgba(20, 20, 20, 0.2);
  }

  .settings--bank-header {
    margin-bottom: 80px;
  }

  .settings--bank-img1 {
    width: 50px;
    height: 50px;
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    margin-right: 16px;
  }

  .settings--bank-t1 {
    color: #141414;
  }

  .settings--bank-t2 {
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }

  .settings--bank-body {
    margin-top: 20px;
  }

  .settings--bank-img2 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .settings--bank-t3 {
    position: relative;
    font-size: 14px;
    top: -13px;
  }

  .settings--bank-t4 {
    position: relative;
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    left: 82px;
    top: -29px;
  }

  .settings--bank-icon1 {
    font-size: 18px;
    margin-top: -40px;
  }

  .settings--bank-icon2 {
    position: relative;
    font-size: 20px;
    background-color: #2C3C5D;
    color: #fff;
    padding: 18px;
    border-radius: 100%;
    bottom: -100px;
  }

  #s-b-delete {
    display: none;
  }

  #s-b-delete-confirm {
    display: none;
  }

  .s-b-delete {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-icon1 {
    color: #D83E3E;
    font-size: 54px;
    background: rgba(216, 62, 62, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }






  .s-b-delete-confirm {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-confirm-icon2 {
    color: #008000;
    font-size: 54px;
    background: rgba(0, 128, 0, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-confirm-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-confirm-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-confirm-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-confirm-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }


  .s-b-add-account {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-add-account-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-add-account-t2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .s-b-add-account-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 14px;
  }

  .s-b-add-account-input2 {
    padding: 12px;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-top: 0px;
    color: #979797;
  }

  .s-b-add-account-btn {
    background-color: #2C3C5D;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px;
    margin-top: 6px;
  }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

  .settings--sidebar {
    margin-left: 32px;
    margin-top: 50px;
    width: 300px;
}

.settings--sidebar-t1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-bottom: 24px;
}

.settings--sidebar-t2 {
    color: #141414
}

.settings--sidebar-icon {
    font-size: 16px;
    background: rgba(44, 60, 93, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 16px;
}

.settings--sidebar-div {
    margin-bottom: 24px;
    cursor: pointer;
}

.settings--sidebar-div:hover {
    background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-radius: 8px;
}

.settings--sidebar-div:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

.scripted:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

#settings-side {
  display: block;
}






.settings--profile {
    margin-left: 320px;
    margin-top: 50px;
}

.settings--profile-col1 {
    padding: 0px 55px;
    margin-top: -24px;
}

.settings--profile-col2 {
    padding: 0px 55px;
}

.settings--profile-div1 {
    margin-bottom: 44px;
}

.settings--profile-div2 {
    margin-bottom: 24px;
}

.settings--profile-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--profile-btn1 {
    width: 100px;
    font-size: 12px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-top: -64px;
}

.settings--profile-t2 {
    margin-bottom: 18px;
}

.settings--profile-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.settings--profile-icon {
    color: #0F49C0;
    background: rgba(15, 73, 192, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 18px;
}

.settings--profile-btn2 {
    width: 85px;
    font-size: 12px;
    background: #0F49C0;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 8px;
}

.settings--profile-btn3 {
    width: 100%;
    background: #2C3C5D;
    opacity: 0.1;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    margin-bottom: 84px;
    border: none;
}

.settings--profile-img {
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    padding: 70px 70px;
    margin-top: 50px;
}

.settings--profile-t3 {
    margin-top: 62px;
    font-size: 22px;
    font-weight: 700;
}

.settings--profile-t3-2 {
    margin-top: -16px;
    font-size: 12px;
}

.settings--profile-banner {
    margin-top: 44px;
    border-radius: 10px;
    background-color: #F7F8F9;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    height: 200px;
    width: 200px;
}

.settings--profile-banner-list {
  margin-top: 24px;
}

.settings--profile-banner-list-color1 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: #F7F8F9;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color1:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color2 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: green;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color2:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color3 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: blue;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color3:active {
  opacity: 0.5;
}


.settings--profile-banner-list-color4 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: yellow;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color4:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color5 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: orange;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color5:active {
  opacity: 0.5;
}


#s-n {
    display: none;
}

#s-a {
    display: none;
}

#s-b {
    display: none;
}


.settings--notification {
    margin-left: 360px;
    margin-top: 50px;
}

.settings--notification-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--notification-t2 {
    margin-top: -12px;
}

.settings--notification-t2-t {
  position: relative;
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  font-weight: 600;
  top: 5px;
}

.settings--notification-tions {
    margin-top: 32px;
    margin-bottom: 50px;
    width: 400px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 16px;
    padding: 26px;
}

.settings--notification-hr {
    width: 113.5%;
    border: 1px solid rgba(20, 20, 20, 0.05);
    margin-left: -24px;
}

.settings--notification-checkbox {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 24px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-checkbox-2 {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 44px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-t3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -26px;
    margin-left: 64px;
}

.settings--notification-t3-3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 64px;
}

.settings--notification-t4 {
    margin-top: -8px;
    margin-left: 64px;
}

.settings--notification-t4 {

}

.settings--notification-btn {
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
    padding: 16px;
    color: #fff;
    width: 160px;
    margin-bottom: 50px;

}














.settings--account {
    margin-left: 400px;
}

.settings--account-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--account-t2 {
    margin-top: -12px;
}

.settings--account-accordions {
    margin-top: 52px;
}

.settings--account-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .settings--account-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .settings--account-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .settings--account-panel-text{
    margin-right: 45%;
    line-height: 32px;
  }    

  .settings--account-select {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .settings--account-btn {
    color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    width: 160px;
  }









  

  .settings--bank {
    margin-left: 360px;
    margin-top: 50px;
  }

  .settings--bank-div {
    width: 420px;
    height: 400px;
    overflow-y: scroll;
    padding: 30px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  }

  .settings--bank-header {
    margin-bottom: 80px;
  }

  .settings--bank-img1 {
    width: 50px;
    height: 50px;
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    margin-right: 16px;
  }

  .settings--bank-t1 {
    color: #141414;
  }

  .settings--bank-t2 {
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }

  .settings--bank-body {
    margin-top: 32px;
  }

  .settings--bank-img2 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .settings--bank-t3 {
    position: relative;
    font-size: 14px;
    top: -13px;
  }

  .settings--bank-t4 {
    position: relative;
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    left: 82px;
    top: -29px;
  }

  .settings--bank-icon1 {
    font-size: 18px;
    margin-top: -40px;
  }

  .settings--bank-icon2 {
    position: relative;
    font-size: 20px;
    background-color: #2C3C5D;
    color: #fff;
    padding: 18px;
    border-radius: 100%;
    bottom: -100px;
  }

  #s-b-delete {
    display: none;
  }

  #s-b-delete-confirm {
    display: none;
  }

  .s-b-delete {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-icon1 {
    color: #D83E3E;
    font-size: 54px;
    background: rgba(216, 62, 62, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }






  .s-b-delete-confirm {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-confirm-icon2 {
    color: #008000;
    font-size: 54px;
    background: rgba(0, 128, 0, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-confirm-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-confirm-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-confirm-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-confirm-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }


  .s-b-add-account {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-add-account-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-add-account-t2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .s-b-add-account-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 14px;
  }

  .s-b-add-account-input2 {
    padding: 12px;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-top: 0px;
    color: #979797;
  }

  .s-b-add-account-btn {
    background-color: #2C3C5D;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px;
    margin-top: 6px;
  }
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

  .settings--sidebar {
    margin-left: 100px;
    margin-top: 50px;
    width: 300px;
}

.settings--sidebar-t1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-bottom: 24px;
}

.settings--sidebar-t2 {
    color: #141414
}

.settings--sidebar-icon {
    font-size: 16px;
    background: rgba(44, 60, 93, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 16px;
}

.settings--sidebar-div {
    margin-bottom: 24px;
    cursor: pointer;
}

.settings--sidebar-div:hover {
    background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-radius: 8px;
}

.settings--sidebar-div:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

.scripted:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

#settings-side {
  display: block;
}






.settings--profile {
    margin-left: 400px;
    margin-top: 50px;
}

.settings--profile-col1 {
    padding: 0px 55px;
    margin-top: -24px;
}

.settings--profile-col2 {
    padding: 0px 55px;
}

.settings--profile-div1 {
    margin-bottom: 44px;
}

.settings--profile-div2 {
    margin-bottom: 24px;
}

.settings--profile-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--profile-btn1 {
    width: 100px;
    font-size: 12px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-top: -64px;
}

.settings--profile-t2 {
    margin-bottom: 18px;
}

.settings--profile-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.settings--profile-icon {
    color: #0F49C0;
    background: rgba(15, 73, 192, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 18px;
}

.settings--profile-btn2 {
    width: 85px;
    font-size: 12px;
    background: #0F49C0;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 8px;
}

.settings--profile-btn3 {
    width: 100%;
    background: #2C3C5D;
    opacity: 0.1;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    margin-bottom: 84px;
    border: none;
}

.settings--profile-img {
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    padding: 70px 70px;
    margin-top: 50px;
}

.settings--profile-t3 {
    margin-top: 62px;
    font-size: 22px;
    font-weight: 700;
}

.settings--profile-t3-2 {
    margin-top: -16px;
    font-size: 12px;
}

.settings--profile-banner {
    margin-top: 44px;
    border-radius: 10px;
    background-color: #F7F8F9;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    height: 200px;
    width: 200px;
}

.settings--profile-banner-list {
  margin-top: 24px;
}

.settings--profile-banner-list-color1 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: #F7F8F9;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color1:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color2 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: green;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color2:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color3 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: blue;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color3:active {
  opacity: 0.5;
}


.settings--profile-banner-list-color4 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: yellow;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color4:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color5 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: orange;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color5:active {
  opacity: 0.5;
}


#s-n {
    display: none;
}

#s-a {
    display: none;
}

#s-b {
    display: none;
}


.settings--notification {
    margin-left: 400px;
    margin-top: 50px;
}

.settings--notification-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--notification-t2 {
    margin-top: -12px;
}

.settings--notification-t2-t {
  position: relative;
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  font-weight: 600;
  top: 5px;
}

.settings--notification-tions {
    margin-top: 32px;
    margin-bottom: 50px;
    width: 600px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 16px;
    padding: 36px;
}

.settings--notification-hr {
    width: 113.5%;
    border: 1px solid rgba(20, 20, 20, 0.05);
    margin-left: -36px;
}

.settings--notification-checkbox {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 24px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-checkbox-2 {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 44px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-t3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -2px;
    margin-left: 24px;
}

.settings--notification-t3-3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 24px;
    margin-top: 18px;
}

.settings--notification-t4 {
    margin-top: -8px;
    margin-left: 24px;
}

.settings--notification-t4 {

}

.settings--notification-btn {
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
    padding: 16px;
    color: #fff;
    width: 160px;
    margin-bottom: 50px;

}














.settings--account {
    margin-left: 400px;
    width: ;
}

.settings--account-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--account-t2 {
    margin-top: -12px;
}

.settings--account-accordions {
    margin-top: 52px;
}

.settings--account-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 50%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .settings--account-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .settings--account-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
    width: 50%;
  }
  .settings--account-panel-text{
    margin-right: 45%;
    line-height: 32px;
  }    

  .settings--account-select {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    width: 50%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .settings--account-btn {
    color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    width: 160px;
  }









  

  .settings--bank {
    margin-left: 450px;
    margin-top: 50px;
  }

  .settings--bank-div {
    width: 450px;
    height: 400px;
    overflow-y: scroll;
    padding: 30px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  }

  .settings--bank-header {
    margin-bottom: 80px;
  }

  .settings--bank-img1 {
    width: 50px;
    height: 50px;
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    margin-right: 16px;
  }

  .settings--bank-t1 {
    color: #141414;
  }

  .settings--bank-t2 {
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }

  .settings--bank-body {
    margin-top: 20px;
  }

  .settings--bank-img2 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .settings--bank-t3 {
    position: relative;
    font-size: 14px;
    top: -13px;
  }

  .settings--bank-t4 {
    position: relative;
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    left: 82px;
    top: -29px;
  }

  .settings--bank-icon1 {
    font-size: 18px;
    margin-top: -40px;
  }

  .settings--bank-icon2 {
    position: relative;
    font-size: 20px;
    background-color: #2C3C5D;
    color: #fff;
    padding: 18px;
    border-radius: 100%;
    bottom: -100px;
  }

  #s-b-delete {
    display: none;
  }

  #s-b-delete-confirm {
    display: none;
  }

  .s-b-delete {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-icon1 {
    color: #D83E3E;
    font-size: 54px;
    background: rgba(216, 62, 62, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }






  .s-b-delete-confirm {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-confirm-icon2 {
    color: #008000;
    font-size: 54px;
    background: rgba(0, 128, 0, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-confirm-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-confirm-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-confirm-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-confirm-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }


  .s-b-add-account {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-add-account-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-add-account-t2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .s-b-add-account-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 14px;
  }

  .s-b-add-account-input2 {
    padding: 12px;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-top: 0px;
    color: #979797;
  }

  .s-b-add-account-btn {
    background-color: #2C3C5D;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px;
    margin-top: 6px;
  }
 
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .settings--sidebar {
    margin-left: 100px;
    margin-top: 50px;
    width: 300px;
}

.settings--sidebar-t1 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-bottom: 24px;
}

.settings--sidebar-t2 {
    color: #141414
}

.settings--sidebar-icon {
    font-size: 16px;
    background: rgba(44, 60, 93, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 16px;
}

.settings--sidebar-div {
    margin-bottom: 24px;
    cursor: pointer;
}

.settings--sidebar-div:hover {
    background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    border-radius: 8px;
}

.settings--sidebar-div:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

.scripted:focus {
  background: linear-gradient(90deg, rgba(44, 60, 93, 0.05) 0%, rgba(12, 70, 211, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
  border-radius: 8px;
}

#settings-side {
  display: block;
}






.settings--profile {
    margin-left: 400px;
    margin-top: 50px;
}

.settings--profile-col1 {
    padding: 0px 55px;
    margin-top: -24px;
}

.settings--profile-col2 {
    padding: 0px 55px;
}

.settings--profile-div1 {
    margin-bottom: 44px;
}

.settings--profile-div2 {
    margin-bottom: 24px;
}

.settings--profile-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--profile-btn1 {
    width: 100px;
    font-size: 12px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 8px;
    background: #fff;
    padding: 12px;
    margin-top: -64px;
}

.settings--profile-t2 {
    margin-bottom: 18px;
}

.settings--profile-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
}

.settings--profile-icon {
    color: #0F49C0;
    background: rgba(15, 73, 192, 0.05);
    padding: 12px;
    border-radius: 100%;
    margin-right: 18px;
}

.settings--profile-btn2 {
    width: 85px;
    font-size: 12px;
    background: #0F49C0;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 8px;
}

.settings--profile-btn3 {
    width: 100%;
    background: #2C3C5D;
    opacity: 0.1;
    border-radius: 8px;
    padding: 16px;
    color: #fff;
    margin-bottom: 84px;
    border: none;
}

.settings--profile-img {
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    padding: 70px 70px;
    margin-top: 50px;
}

.settings--profile-t3 {
    margin-top: 62px;
    font-size: 22px;
    font-weight: 700;
}

.settings--profile-t3-2 {
    margin-top: -16px;
    font-size: 12px;
}

.settings--profile-banner {
    margin-top: 44px;
    border-radius: 10px;
    background-color: #F7F8F9;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    height: 200px;
    width: 200px;
}

.settings--profile-banner-list {
  margin-top: 24px;
}

.settings--profile-banner-list-color1 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: #F7F8F9;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color1:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color2 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: green;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color2:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color3 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: blue;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color3:active {
  opacity: 0.5;
}


.settings--profile-banner-list-color4 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: yellow;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color4:active {
  opacity: 0.5;
}

.settings--profile-banner-list-color5 {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  background-color: orange;
  padding: 12px 20px;
  margin-right: 6px;
  border-radius: 6px;
}

.settings--profile-banner-list-color5:active {
  opacity: 0.5;
}


#s-n {
    display: none;
}

#s-a {
    display: none;
}

#s-b {
    display: none;
}


.settings--notification {
    margin-left: 400px;
    margin-top: 50px;
}

.settings--notification-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--notification-t2 {
    margin-top: -12px;
}

.settings--notification-t2-t {
  position: relative;
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  font-weight: 600;
  top: 5px;
}

.settings--notification-tions {
    margin-top: 32px;
    margin-bottom: 50px;
    width: 600px;
    border: 2px solid rgba(20, 20, 20, 0.05);
    border-radius: 16px;
    padding: 36px;
}

.settings--notification-hr {
    width: 113.5%;
    border: 1px solid rgba(20, 20, 20, 0.05);
    margin-left: -36px;
}

.settings--notification-checkbox {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 24px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-checkbox-2 {
    background: rgba(20, 20, 20, 0.05);
    margin-top: 44px;
    transform: scale(2);
    margin-left: 8px;
}

.settings--notification-t3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: -2px;
    margin-left: 24px;
}

.settings--notification-t3-3 {
  margin-top: 18px;
}

.settings--notification-t3-3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 24px;
}

.settings--notification-t4 {
    margin-top: -8px;
    margin-left: 24px;
}

.settings--notification-t4 {

}

.settings--notification-btn {
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
    padding: 16px;
    color: #fff;
    width: 160px;
    margin-bottom: 50px;

}














.settings--account {
    margin-left: 400px;
    width: ;
}

.settings--account-t1 {
    font-size: 24px;
    font-weight: 700;
}

.settings--account-t2 {
    margin-top: -12px;
}

.settings--account-accordions {
    margin-top: 52px;
}

.settings--account-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 50%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .settings--account-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .settings--account-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
    width: 50%;
  }
  .settings--account-panel-text{
    margin-right: 45%;
    line-height: 32px;
  }    

  .settings--account-select {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 16px;
    width: 50%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .settings--account-btn {
    color: rgba(255, 0, 0, 0.4);
    border: 2px solid rgba(255, 0, 0, 0.2);
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    width: 160px;
  }









  

  .settings--bank {
    margin-left: 450px;
    margin-top: 50px;
  }

  .settings--bank-div {
    width: 450px;
    height: 400px;
    min-height: 400px;
    padding: 30px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
  }

  .settings--bank-img1 {
    width: 50px;
    height: 50px;
    background: rgb(213, 41, 66);
    border: 4px solid #FFFFFF;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    border-radius: 100%;
    margin-right: 16px;
  }

  .settings--bank-header {
    margin-bottom: 80px;
  }

  .settings--bank-t1 {
    color: #141414;
  }

  .settings--bank-t2 {
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }

  .settings--bank-body {
    margin-top: 20px;
  }

  .settings--bank-img2 {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 20px;
  }

  .settings--bank-t3 {
    position: relative;
    font-size: 14px;
    top: -13px;
  }

  .settings--bank-t4 {
    position: relative;
    color: rgba(44, 60, 93, 0.8);
    font-size: 14px;
    left: 82px;
    top: -29px;
  }

  .settings--bank-icon1 {
    font-size: 18px;
    margin-top: -40px;
  }

  .settings--bank-icon2 {
    position: relative;
    font-size: 20px;
    background-color: #2C3C5D;
    color: #fff;
    padding: 18px;
    border-radius: 100%;
    bottom: -100px;
  }

  #s-b-delete {
    display: none;
  }

  #s-b-delete-confirm {
    display: none;
  }

  .s-b-delete {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-icon1 {
    color: #D83E3E;
    font-size: 54px;
    background: rgba(216, 62, 62, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }






  .s-b-delete-confirm {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-delete-confirm-icon2 {
    color: #008000;
    font-size: 54px;
    background: rgba(0, 128, 0, 0.05);
    padding: 24px;
    border-radius: 100%;
    margin-left: 38%;
  }

  .s-b-delete-confirm-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-delete-confirm-btn1 {
    width: 100%;
    color: #fff;
    padding: 12px;
    background: #2C3C5D;
    border: 2px solid #2C3C5D;
    border-radius: 8px;
    border: none;
  }

  .s-b-delete-confirm-btn2 {
    color: #2C3C5D;
    text-align: center;
    border: none;
    background-color: #fff;
  }

  .s-b-delete-confirm-btn2:hover {
    background-color: #2C3C5D;
    color: #fff;
    text-align: center;
    border: none;
    padding: 12px;
    border-radius: 8px;
  }


  .s-b-add-account {
    width: 450px;
    background: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
    padding: 30px;
  }

  .s-b-add-account-t1 {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .s-b-add-account-t2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .s-b-add-account-input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 14px;
  }

  .s-b-add-account-input2 {
    padding: 12px;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 14px;
    margin-top: 0px;
    color: #979797;
  }

  .s-b-add-account-btn {
    background-color: #2C3C5D;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px;
    margin-top: 6px;
  }
 } 


