 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .coming--soon {
    margin: 50px 20px;
}

.coming--soon-col1 {
    padding: 0px;
}

.coming--soon-col1-t1 {
    color: #141414;
    font-weight: 700;
}

.coming--soon-col1-t2 {
    font-size: 64px;
    font-weight: 600;
    width: 410px;
    line-height: 80px;
    margin-top: 42px;
}

.coming--soon-col1-btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.coming--soon-col1-btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.coming--soon-col1-btn1:active {
  border: 5px solid #2C3C5D;
}

.coming--soon-col1-btn2 {
    border: none;
    background: #fff;
}

.coming--soon-col2 {
    padding: 82px 32px;
}

.coming--soon-accordions {
    margin-top: 0px;
}

.coming--soon-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .coming--soon-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .coming--soon-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .coming--soon-panel-text{
    width: 90%;
    line-height: 32px;
  }    
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .coming--soon {
    margin: 50px 50px;
}

.coming--soon-col1 {
    padding: 32px;
}

.coming--soon-col1-t1 {
    color: #141414;
    font-weight: 700;
}

.coming--soon-col1-t2 {
    font-size: 64px;
    font-weight: 600;
    width: 410px;
    line-height: 80px;
    margin-top: 42px;
}

.coming--soon-col1-btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.coming--soon-col1-btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.coming--soon-col1-btn1:active {
  border: 5px solid #2C3C5D;
}

.coming--soon-col1-btn2 {
    border: none;
    background: #fff;
}

.coming--soon-col2 {
    padding: 82px 32px;
}

.coming--soon-accordions {
    margin-top: 0px;
}

.coming--soon-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .coming--soon-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .coming--soon-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .coming--soon-panel-text{
    width: 90%;
    line-height: 32px;
  }    
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  .coming--soon {
    margin: 50px 100px;
}

.coming--soon-col1 {
    padding: 32px;
}

.coming--soon-col1-t1 {
    color: #141414;
    font-weight: 700;
}

.coming--soon-col1-t2 {
    font-size: 64px;
    font-weight: 600;
    width: 410px;
    line-height: 80px;
    margin-top: 42px;
}

.coming--soon-col1-btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.coming--soon-col1-btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.coming--soon-col1-btn1:active {
  border: 5px solid #2C3C5D;
}

.coming--soon-col1-btn2 {
    border: none;
    background: #fff;
}

.coming--soon-col2 {
    padding: 82px 32px;
}

.coming--soon-accordions {
    margin-top: 0px;
}

.coming--soon-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .coming--soon-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .coming--soon-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .coming--soon-panel-text{
    width: 90%;
    line-height: 32px;
  }    
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  .coming--soon {
    margin: 50px 100px;
}

.coming--soon-col1 {
    padding: 32px;
}

.coming--soon-col1-t1 {
    color: #141414;
    font-weight: 700;
}

.coming--soon-col1-t2 {
    font-size: 64px;
    font-weight: 600;
    width: 410px;
    line-height: 80px;
    margin-top: 42px;
}

.coming--soon-col1-btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.coming--soon-col1-btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.coming--soon-col1-btn1:active {
  border: 5px solid #2C3C5D;
}

.coming--soon-col1-btn2 {
    border: none;
    background: #fff;
}

.coming--soon-col2 {
    padding: 82px 32px;
}

.coming--soon-accordions {
    margin-top: 0px;
}

.coming--soon-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .coming--soon-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .coming--soon-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .coming--soon-panel-text{
    width: 90%;
    line-height: 32px;
  }    
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .coming--soon {
    margin: 50px 100px;
}

.coming--soon-col1 {
    padding: 32px;
}

.coming--soon-col1-t1 {
    color: #141414;
    font-weight: 700;
}

.coming--soon-col1-t2 {
    font-size: 64px;
    font-weight: 600;
    width: 410px;
    line-height: 80px;
    margin-top: 42px;
}

.coming--soon-col1-btn1 {
    background: #2C3C5D;
    color: #fff;
    border-radius: 8px;
    border: none;
    width: 200px;
    margin-right: 32px;
    padding: 14px;
}

.coming--soon-col1-btn1:hover {
  border: 3px solid #2C3C5D;
  background-color: #fff;
  color: #0D0D2B;
}

.coming--soon-col1-btn1:active {
  border: 5px solid #2C3C5D;
}

.coming--soon-col1-btn2 {
    border: none;
    background: #fff;
}

.coming--soon-col2 {
    padding: 82px 32px;
}

.coming--soon-accordions {
    margin-top: 0px;
}

.coming--soon-accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.4s;
    border-bottom: 2px solid rgba(20, 20, 20, 0.05);
    border-left: none;
    border-right: none;
    border-top: none;
  }
  
  .coming--soon-accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
  }
  .active:after {
    content: "\2212";
  }
  
  .coming--soon-panel {
    display: flex;
    padding: 0px 18px;
    background-color: white;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    text-align: left;
    gap: 32px;
  }
  .coming--soon-panel-text{
    width: 90%;
    line-height: 32px;
  }    
 } 

