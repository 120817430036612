 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 30px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 30px;
    }
    
    .profile--left {
        margin-left: 30px;
    }
    
    .profile--right {
        margin-right:10px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 0px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        width: 130px;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .profile--t3-base {
        display: flex;
        flex-direction: row;
        gap: 22px;
        overflow: scroll;
        padding-bottom: 24px;
        margin-bottom: -24px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 20px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
        margin-bottom: -22px;
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 10px;
        padding: 12px;
        position: absolute;
        right: 30px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
        position: relative;
        top: -110px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 0px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 10px;
    }
    
    .profile--input-2 {
        width: 100px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
        overflow: scroll;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 30px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 30px;
    }
    
    .profile--left {
        margin-left: 30px;
    }
    
    .profile--right {
        margin-right:10px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 20px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
    
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 12px;
        padding: 12px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 0px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 350px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 20px;
    }
    
    .profile--input-2 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
        overflow: scroll;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {

    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 100px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 100px;
    }
    
    .profile--left {
        margin-left: 100px;
    }
    
    .profile--right {
        margin-right: 100px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 100px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
    
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 12px;
        padding: 12px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 30px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 430px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 20px;
    }
    
    .profile--input-2 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 100px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 100px;
    }
    
    .profile--left {
        margin-left: 100px;
    }
    
    .profile--right {
        margin-right: 100px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 100px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
    
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 12px;
        padding: 12px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 70px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 550px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 50px;
    }
    
    .profile--input-2 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 100px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 100px;
    }
    
    .profile--left {
        margin-left: 100px;
    }
    
    .profile--right {
        margin-right: 100px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 100px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
    
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 12px;
        padding: 12px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 70px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 750px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 50px;
    }
    
    .profile--input-2 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }
 } 

 /* Extra large devices (large laptops and desktops, 1500px and up) */
 @media only screen and (min-width: 1500px) {
    .profile--hero {
        /* background: #F7F8F9; */
        height: 200px;
    }
    
    .profile--img {
        width: 150px;
        height: 150px;
        background-color: #d52942;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.2);
        border-radius: 100%;
        margin-left: 200px;
        margin-top: 130px;
        z-index: 1000;
    }
    
    .profile--t1 {
        font-size: 32px;
        font-weight: 700;
        margin-left: 200px;
    }
    
    .profile--left {
        margin-left: 200px;
    }
    
    .profile--right {
        margin-right: 200px;
    }
    
    .profile--icon1 {
        position: relative;
        font-size: 20px;
        margin-right: 24px;
        top: -24px;
    }
    
    .profile--t2 {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -22px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile--t2b {
        display: inline-block;
        position: relative;
        font-size: 14px;
        color: rgba(44, 60, 93, 0.8);
        margin-right: 32px;
        top: -26px;
    }
    
    .profile--section {
        margin-top: 280px;
        margin-left: 200px;
        margin-right: 200px;
    }
    
    .profile--t3 {
        color: rgba(44, 60, 93, 0.8);
        margin-right: 100px;
        cursor: pointer;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .profile--t3:active {
        color: #141414;
    }
    
    
    /*.......*/
    
    
    .orderss:focus {
        color: #141414;
        border-bottom: 2px solid #141414;
        padding-bottom: 20px;
    
    }
    
    
    /*.......*/
    
    
    
    .profile--menu {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
        border-radius: 15px;
        margin-top: 12px;
        padding: 12px;
    }
    
    .profile--menu-t:hover {
        opacity: 0.6;
    }
    
    
    .profile--icon2 {
        font-size: 20px;
        padding: 18px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2b {
        font-size: 20px;
        padding: 18px 25px;
        background: rgba(20, 20, 20, 0.02);
        border-radius: 100%;
        margin-right: 24px;
    }
    
    .profile--icon2:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2b:hover {
        background: rgba(20, 20, 20, 0.2);
    }
    
    .profile--icon2cc {
        width: 24px;
    }
    
    .profile--icon2c {
        font-size: 20px;
        margin-right: 12px;
    }
    
    .profile--icon3 {
        width: 42px;
        padding: 8px;
        margin-right: 70px;
        margin-left: 2px;
    }
    
    .profile--input-1 {
        width: 1050px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
        margin-right: 70px;
    }
    
    .profile--input-2 {
        width: 200px;
        padding: 12px;
        border: 1.2px solid #F1F1F1;
        border-radius: 8px;
    }
    
    .sell--orders-list {
        width: 100%;
        height: 300px;
        border: 1px solid #979797;
        border-radius: 20px;
        padding: 100px 500px;
        margin-top: 30px;
    }
    
    #sell-orders {
        display: block;
    }
    
    #buy-orders {
        display: none;
    }
    
    #activities {
        display: none;
    }

    #pending-orders {
        display: none;
    }
    
    .profile--open_profile_menu {
        display: block;
    }

    .profile--table-div {
        overflow: scroll;
    }
    
    .profile--table {
        margin: 44px 24px;
        border-collapse: collapse;
        width: 98%;
    }
    
    
    .profile--table-head {
        padding: 24px;
        text-align: left;
        color: #141414;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-head-h {
        padding: 24px 54px;
        margin-left: 60px;
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid #14141410;
    }
    
    
    .profile--table-row {
        padding: 32px;
        text-align: left;
        color: #141414;
        border-bottom: 1px solid #14141410;
    }
    
    .profile--table-icon {
        font-size: 18px;
        margin-right: 12px;
    }
 } 