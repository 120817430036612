 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {

    .connect {
        margin: 100px 20px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {


    .connect {
        margin: 100px 100px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
 
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {


    .connect {
        margin: 100px 200px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
 
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {


    .connect {
        margin: 100px 300px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
 
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .connect {
        margin: 120px 480px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
 } 

  /* Extra large devices (large laptops and desktops, 1500px and up) */
  @media only screen and (min-width: 1500px) {
    .connect {
        margin: 120px 580px;
        margin-bottom: 180px;
    }
    
    .connect--t1 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #141414;
    }
    
    .connect--t2 {
        text-align: center;
        color: #141414;
    }
    
    .connect--div1 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
    }

    .connect--div1:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--div2 {
        padding: 24px;
        background: #FEFEFE;
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .connect--div2:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    
    .connect--img1 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--img2 {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    
    .connect--t3 {
        position: relative;
        font-size: 20px;
        top: 4px;
    }
    
    .connect--tag {
        color: #fff;
        text-align: center;
        width: 80px;
        font-size: 12px;
        padding: 6px;
        background-color: #0F49C0;
        border-radius: 7px;
        margin-top: 4px;
    }
} 



